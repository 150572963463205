[data-effects] .effects-clones-wrapper,
.section-thumbnail-container .effects-clones-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  &.is-fixed {
    max-height: 100%;
  }
}
