.HideOffscreen__media {
  display: none !important;
}

.HideOffscreen__media--onscreen {
  display: block !important;
}

.HideOffscreen__media--offscreen {
  display: none !important;
}
