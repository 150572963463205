@media (orientation: portrait) {
  .Responsive--hide-portrait {
    display: none !important;
  }
}
@media (orientation: landscape) {
  .Responsive--hide-landscape {
    display: none !important;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvcmVzcG9uc2l2ZSIsInNvdXJjZXMiOlsiX29yaWVudGF0aW9uLnNjc3MiLCJyZXNwb25zaXZlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUNJQTtJQUNFOzs7QURDRjtFQ0lBO0lBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAbWl4aW4gb3JpZW50YXRpb24tcG9ydHJhaXQge1xuICBAbWVkaWEgKG9yaWVudGF0aW9uOiBwb3J0cmFpdCkge1xuICAgIEBjb250ZW50O1xuICB9XG59XG5cbkBtaXhpbiBvcmllbnRhdGlvbi1sYW5kc2NhcGUge1xuICBAbWVkaWEgKG9yaWVudGF0aW9uOiBsYW5kc2NhcGUpIHtcbiAgICBAY29udGVudDtcbiAgfVxufSIsIi8vIE9yaWVudGF0aW9uIHJlc3BvbnNpdmUgdXRpbGl0aWVzXG5cbkBpbXBvcnQgJy4vb3JpZW50YXRpb24nO1xuXG5AaW5jbHVkZSBvcmllbnRhdGlvbi1wb3J0cmFpdCB7XG4gIC5SZXNwb25zaXZlLS1oaWRlLXBvcnRyYWl0IHtcbiAgICBkaXNwbGF5OiBub25lICFpbXBvcnRhbnQ7XG4gIH1cbn1cblxuQGluY2x1ZGUgb3JpZW50YXRpb24tbGFuZHNjYXBlIHtcbiAgLlJlc3BvbnNpdmUtLWhpZGUtbGFuZHNjYXBlIHtcbiAgICBkaXNwbGF5OiBub25lICFpbXBvcnRhbnQ7XG4gIH1cbn1cbiJdfQ== */