@use "@shorthand/design-system/colours" as colours;
@import "../responsive/breakpoints";

// ADD SPACE FOR CAPTION
.Theme-Section-HasOverlay {
  .GridSection--Full.GridSection--gutters-None {
    padding-bottom: 40px;
  }
}

.GridSection-container.Layer--one {
  // OVERRIDE .Layer--one Z-INDEX TO ALLOW CAPTIONS TO BE CLICKABLE
  z-index: initial;
  .Layout {
    z-index: 100;
  }
}

.GridSection--Full {
  width: 100%;

  .Layout {
    max-width: 100% !important;
  }

  .GridSection__rowContainer {
    margin: auto;
  }
}

.GridSection-title:not(:empty) {
  margin: 0 auto 20px;
}

.GridSection__rowContainer {
  &:first-of-type {
    .GridItem {
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }
  }
  &:last-of-type {
    .GridItem {
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }
    }
  }
  + .GridSection__rowContainer {
    .GridSection--gutters-None & {
      .GridItem--inner {
        border-top: none;
      }
    }
  }
}

.GridSection__row--1 {
  .Grid_image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.GridSection__row {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}

.GridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  @include media-breakpoint-up(md) {
    width: 1%;
  }

  // DEFAULT
  &:only-child {
    @include media-breakpoint-up(md) {
      .GridItem--inner {
        align-items: center;
        &.GridItem--media-left {
          flex-direction: row;
        }

        &.GridItem--media-right {
          flex-direction: row-reverse;
        }
      }
      .InnerText {
        justify-content: center;
        width: 50%;
      }
      .Grid_image:not(.Grid_imageAdd) {
        width: 50%;
        .InlineMedia--image__inner:not(.crop-circle) + .Theme-Caption {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  &:first-of-type {
    @include media-breakpoint-up(md) {
      padding-left: 0;
    }
  }

  &:last-of-type {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
  }

  // SMALL
  .GridSection--gutters-Small & {
    &:first-of-type {
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }

    &:last-of-type {
      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }
  }

  // NONE
  .GridSection--gutters-None & {
    padding: 0;
    @include media-breakpoint-up(md) {
      + .GridItem {
        margin-left: -1px;
        .GridItem--inner {
          border-left-color: transparent;
        }
      }
    }
  }

  &:not(:only-child) .InlineMedia {
    + .InnerText .Theme-Layer-BodyText--inner:not(:empty) {
      padding-top: 0.5em;
    }
  }

  .InnerText {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .Theme-Layer-BodyText--inner:not(:empty) {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1.2em;
  }

  .Grid_image {
    margin: 0;
    border: none;
    width: 100%;
    .Theme-Layer-ResponsiveMedia {
      width: 100%;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

.GridItem--inner {
  display: flex;
  width: 100%;
  height: 100%;

  &.GridItem--media-left {
    flex-direction: column;
  }

  &.GridItem--media-right {
    flex-direction: column;

    @media screen and (min-width: 900px) {
      flex-direction: column-reverse;
    }
  }

  // MOVE TO THEME
  border: 1px solid rgba(colours.$gray-light, 0.3);
}

.DeviceDetect--isIE .GridSection__row:not(.GridSection__row--1) .GridItem--inner.GridItem--media-left {
  display: block;
}

.DeviceDetect--isIE .GridItem .InnerText .InlineMedia img {
  max-height: 50vh;
}
