@use "@shorthand/design-system/colours" as colours;
.Prime3 {
  color: colours.$white !important;
  background-color: colours.$preview-footer-prime-1 !important;

  > .Layout {
    align-items: baseline !important;
  }
}

.Prime3:is(div) {
  box-shadow: 0 13px 9px 5px rgba(0, 0, 0, 0.1);
}

.prime3FooterLower {
  color: colours.$white !important;
  background-color: colours.$shorthand-orange !important;
}

.prime3-items {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.5em;
  }

  div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 1em;
    padding: 0.75em 0;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    @media screen and (max-width: 767px) {
      margin: 0.25em 0;
      padding: 0.25em 0;
      font-size: 11px;
    }
  }

  a {
    color: colours.$white !important;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }
  }

}

