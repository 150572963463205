/* Rules specific to embedding */
.shorthand-embed-article .FullSize {
  height: 100%;
}
.shorthand-embed-article .DisplayContainerHeight {
  height: 100vh;
}
.shorthand-embed-article .FullSize--basic {
  height: 100%;
}
