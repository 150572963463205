// =======================================================
//                 STYLEGUIDE COMPLIANT
// =======================================================
// Accent colors
$shorthand-teal: hsl(169 86% 32%);
$shorthand-teal-light: rgba($shorthand-teal, 0.1);
$shorthand-blue: #00a7cf;
$shorthand-blue-light: rgba($shorthand-blue, 0.1);
$shorthand-orange: #f2744d;
$shorthand-orange-light: rgba($shorthand-orange, 0.075);
$shorthand-green: hsl(153 91% 30%);
$shorthand-green-light: rgba($shorthand-green, 0.1);
$shorthand-red: hsl(4 86% 58%);
$shorthand-red-light: rgba($shorthand-red, 0.1);
$shorthand-gold: #ccb87e;
$shorthand-gold-light: rgba($shorthand-gold, 0.1);

// Brand colors (for bootstrap)
$brand-success: $shorthand-green;
$brand-danger: $shorthand-red;
$link-color: $shorthand-teal;

// Gray palette
$gray-lightest: #f9f9f9;
$gray-lighter: #eee;
$gray-light: #aaa;
$gray: #777;
$gray-dark: #424242;
$gray-darker: #292929;
$gray-darkest: #1c1c1c;
$white: #fff !default;
$black: #000 !default;

// =======================================================
//             NON STYLEGUIDE COMPLIANT
// =======================================================
// These are unique values that are not part of the styleguide. Please avoid unique values where possible
// If you do, add it here and raise an issue in linear

// Grays
$emptyImage-border: #e2e2e2;
$admin-3: #fffeef;
$react-select-1: #fcfcfc;
$admin-2: #fafafa;
$form-1: #f5f5f5;
$self-serve-1: #f2f2f2;
$code-2: #e8f2ff;
$dashboard-2: #e6e6e6;
$admin-5: #ddd;
$admin-4: #ccc;
$form-2: #c4c4c4;
$date-picker-2: #aeaeae;
$date-picker-1: #999999;
$share-2: #999;
$portal-1: #888;
$preview-footer-1: #848484;
$dashboard-1: #787878;
$inline-media-1: #657786;
$sidebar-1: #546e7a;
$self-serve-2: #616161;
$nav-1: #666;
$section-settings-1: #3a3a3a;
$index-1: #333;
$preview-footer-2: #353535;
$sidebar-2: #263238;
$self-serve-3: #292929;
$preview-footer-3: #282828;
$editor: #222;
$preview-footer-prime-1: #141414;
$slider-gray: #d9d9d9;
$workspace-gray: #f3f4f6;

// Accents
$menu-1: #3b5998;
$react-date-picker-2: #216ba5;
$menu-2: #1da1f2;

$inline-media-3: #167;
$code-5: #219;
$full-size-1: #399;
$collections-1: #add;

$admin-1: #a9a687;
$code-4: #cc7;

$react-date-picker-1: #3dcc4a;
$code-3: #164;

$error-1: #4b3083;

$publishing-1: #f8e6e2;
$admin-5: #fee;
$full-size-2: #956;
$publishing-2: #f2744c;
$code-1: #ff1717;
$plans-footer-subHeading: #0006;
$plan-border: #e5e7eb;

// other tones added from figma designs not in design system tokens

$tone-dark-100: #38393a;
$tone-dark-50: #505050;
