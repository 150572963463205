[data-lazyload-item] {
  & img,
  & > div {
    opacity: 0;
    transition: 1000ms ease-out opacity;
  }
}

.Lazyload--isLoaded [data-lazyload-item] {
  & img,
  & > div {
    opacity: 1;
  }
}

.Lazyload__loading {
  transition: opacity 100ms ease-out;
  transition-delay: 1s;
  opacity: 1;

  .Lazyload--isLoaded & {
    opacity: 0;
  }
}

.DeviceDetect--isiOS .Lazyload__spinner {
  display: none;
}
