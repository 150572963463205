@import "./grid";
@import "./snap";

.Layout__row {
  /* For RTL Themes this will get flipped back to rtl for text */
  direction: ltr;
}

.Layout--equalHeights {
  .Layout__row {
    display: flex;
  }
  [class*="Layout__col-"] {
    float: none;
  }
}

.Layout--flexboxUnsupported .Layout--equalHeights {
  display: table;
  .Layout__row {
    display: table-row;
  }
  [class*="Layout__col-"] {
    display: table-cell;
  }
}

.Layout__snap--xleft {
  @include snap-x(left);
}

.Layout__snap--xcenter {
  @include snap-x(center);
}

.Layout__snap--xright {
  @include snap-x(right);
}

.Layout__flex--xcenter {
  justify-content: center;
  text-align: center;
}

.Layout__flex--xleft {
  justify-content: flex-start;
  text-align: left;
}

.Layout__flex--xright {
  justify-content: flex-end;
  text-align: right;
}

/**
 * Y Axis
 */

.Layout__snap--ytop {
  @include snap-y(top);
}

.Layout__snap--ycenter {
  @include snap-y(center);
}

.Layout__snap--ybottom {
  @include snap-y(bottom);
}

.Layout__flex--ycenter {
  align-items: center;
}

.Layout__flex--ytop {
  align-items: flex-start;
}

.Layout__flex--ybottom {
  align-items: flex-end;
}

/**
 * Both Axis Centered Override
 */

.Layout__snap--ycenter.Layout__snap--xcenter {
  @include snap(center, center);
}

/**
 * Column alignment helpers
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .Layout__col-#{$breakpoint}-center {
      margin-right: auto;
      margin-left: auto;
    }

    .Layout__col-#{$breakpoint}-right {
      margin-right: 0;
      margin-left: auto;
    }

    .Layout__col-#{$breakpoint}-left {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

@media (min-width: 620px) and (max-width: 899px) {
  .Layout__offset-sm-0 {
    margin-left: auto;
    margin-right: auto;
  }
}
