@use "@shorthand/design-system/colours" as colours;

.Header_socialIcons {
  &:focus-visible {
    div {
      outline: 2px solid colours.$shorthand-teal;
    }
  }
}

.Header_socialIcons div {
  margin-right: -5px;
  margin-top: -1px;
}
