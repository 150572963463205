$displayContainerHeight: 100vh;
@import "../responsive/orientation";

.DisplayContainerHeight {
  height: $displayContainerHeight;
  overflow: hidden;
}

.DisplayContainerHeight--minHeight {
  min-height: $displayContainerHeight;
}

@include orientation-landscape {
  .DisplayContainerHeight--landscapeOnly {
    height: $displayContainerHeight;
  }
}

@include orientation-portrait {
  .DisplayContainerHeight--portraitOnly {
    height: $displayContainerHeight;
  }
}

.DisplayContainerHeight--height150 {
  min-height: 150vh;
}

.DisplayContainerHeight--heightHalf {
  height: calc($displayContainerHeight / 2);
  overflow: hidden;
}

.DisplayContainerHeight--minHeightHalf {
  min-height: calc($displayContainerHeight / 2);
  height: auto;
}

.DisplayContainerHeight--heightThird {
  height: calc($displayContainerHeight / 3);
  overflow: hidden;
}

.DisplayContainerHeight--minHeightThird {
  min-height: calc($displayContainerHeight / 3);
  height: auto;
}

/*
  Flexible height TOMs with videos use sticky, which means we can't 
  have overflow: hidden on any parents
*/
.DisplayContainerHeight--heightThird.DisplayContainerHeight--flex,
.DisplayContainerHeight--heightHalf.DisplayContainerHeight--flex,
.DisplayContainerHeight.DisplayContainerHeight--flex {
  height: auto;
  overflow: unset;
}

.DisplayContainerHeight--flex .DisplayContainerHeight img {
  height: 100vh;
}

.DisplayContainerHeight--flex .DisplayContainerHeight video {
  height: 100vh;
}

.DisplayContainerHeight--flex .DisplayContainerHeight .AnimatedImage {
  height: 100vh;
}
