.MediaRenderer__textBlocks {
  margin-top: -100vh;
  pointer-events: none;
}

sh-background-transition .Theme-OverlayedCaption {
  display: none;
}

.Theme-Layer-BodyText {
  pointer-events: all;
}

/* First frame is preset with z-index 1 so that it sits on top
 * until the code finishes loading
 */
sh-background-transition > :first-child {
  z-index: 1;
}

/**
 * This deals with the occasional 1px gap at the bottom of the reveal section due to floating-point heights
 */
sh-background-transition {
  margin-bottom: -0.5px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvYmFja2dyb3VuZC10cmFuc2l0aW9uIiwic291cmNlcyI6WyJiYWNrZ3JvdW5kLXRyYW5zaXRpb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0FBQUE7QUFBQTtBQUdBO0VBQ0U7OztBQUdGO0FBQUE7QUFBQTtBQUdBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuTWVkaWFSZW5kZXJlcl9fdGV4dEJsb2NrcyB7XG4gIG1hcmdpbi10b3A6IC0xMDB2aDtcbiAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG59XG5zaC1iYWNrZ3JvdW5kLXRyYW5zaXRpb24gLlRoZW1lLU92ZXJsYXllZENhcHRpb24ge1xuICBkaXNwbGF5OiBub25lO1xufVxuXG4uVGhlbWUtTGF5ZXItQm9keVRleHQge1xuICBwb2ludGVyLWV2ZW50czogYWxsO1xufVxuXG4vKiBGaXJzdCBmcmFtZSBpcyBwcmVzZXQgd2l0aCB6LWluZGV4IDEgc28gdGhhdCBpdCBzaXRzIG9uIHRvcFxuICogdW50aWwgdGhlIGNvZGUgZmluaXNoZXMgbG9hZGluZ1xuICovXG5zaC1iYWNrZ3JvdW5kLXRyYW5zaXRpb24gPiA6Zmlyc3QtY2hpbGQge1xuICB6LWluZGV4OiAxO1xufVxuXG4vKipcbiAqIFRoaXMgZGVhbHMgd2l0aCB0aGUgb2NjYXNpb25hbCAxcHggZ2FwIGF0IHRoZSBib3R0b20gb2YgdGhlIHJldmVhbCBzZWN0aW9uIGR1ZSB0byBmbG9hdGluZy1wb2ludCBoZWlnaHRzXG4gKi9cbnNoLWJhY2tncm91bmQtdHJhbnNpdGlvbiB7XG4gIG1hcmdpbi1ib3R0b206IC0wLjVweDtcbn1cbiJdfQ== */