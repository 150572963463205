sh-model {
  display: block;
  max-height: 100vh;
}

html:not(.DeviceDetect--isIE):not(.DeviceDetect--isEdge) .isAnimated > div:not(.WebGLMissing) {
  .AnimatedImage__fallback,
  .AnimatedImage__fallbackOverlay {
    display: none;
  }
}

.isAnimated .WebGLMissing .AnimatedImage__instant {
  display: none;
}

.DisplayContainerHeight--height150 {
  .AnimatedImage__background,
  .AnimatedImage__foreground,
  .AnimatedImage__instant img {
    height: 100vh;
  }

  .FullSize--scrollChild .AnimatedImage__instant img {
    position: sticky;
  }
}

// Added dynamically to other background media
.Theme-BackgroundModel .AnimatedImage__instant {
  position: absolute;
  top: 0;

  // Ensure instant sits in front of canvas
  z-index: 2;
}

// Smooth transition between instant and model
.AnimatedImage {
  transition: filter 1300ms ease-in;
}

.InstantImage--isLoading .AnimatedImage {
  filter: blur(13px);
}

.InstantImage--isLoaded .AnimatedImage {
  filter: none;
}

button.gyroscope-motion-access {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  color: white;
  font-family: sans-serif;
  opacity: 0.4;
  background-color: #ffffff00;
  border: none;
}
