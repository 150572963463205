@import "../core/bootstrap-variables";

/**
 * Bootstrap class names be webpack, to the following
 *
 * .container -> .Layout
 * .row -> .Layout__row
 * .col-$$ -> Layout__col-$
 * .push-$$ -> Layout__push-$
 * .pull-$$ -> Layout__pull-$
 * .offset-$$ -> Layout__offset-$
 */

@import "../../../lib/common/src/vendor/bootstrap/bootstrap-grid.scss";

