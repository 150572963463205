// =======================================================
// FONTS
// =======================================================
$font-family-primary: CircularXXSub, sans-serif;
$font-family-secondary: CircularXXSub, sans-serif;
$font-family-passenger: PassengerSerif, sans-serif;
$font-family-serif: CircularXXSub, sans-serif;
$font-family-base: $font-family-secondary;
$font-family-code: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
$ui-text-font-family: $font-family-secondary;
$headings-font-family: $font-family-primary;
$font-awesome: "Font Awesome 5 Free";
// =======================================================
//  UN DOCUMENTED FONTS
// =======================================================
// These are unique values that are not part of the styleguide. Please avoid unique values where possible
// If you do, add it here and raise an issue in linear
$font-family-georgia: Georgia, serif;
$font-family-impact: Impact, Haettenschweiler, "Arial Narrow", sans-serif;
$font-family-arial: Arial, sans-serif;
$font-family-helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-monospace: monospace;
// =======================================================
//  FONT SIZES
// =======================================================
$font-size-xs: 0.6875rem;
$font-size-sm: 0.1825rem;
$small-font-size: 0.75rem;
$btn-font-size: 0.812rem !default;
$btn-font-size-sm: 0.687rem !default;
$btn-font-size-xs: 0.625rem !default;
$btn-font-size-lg: 1.125rem !default;
// =======================================================
//  UN DOCUMENTED FONT SIZES  rems
// =======================================================
// These are unique values that are not part of the styleguide. Please avoid unique values where possible
// If you do, add it here and raise an issue in linear
$font-size-0812: 0.812rem;
$font-size-0825: 0.825rem;
$font-size-0875: 0.875rem;
$font-size-375: 3.75rem;
$font-size-075: 0.75rem;
$font-size-1: 1rem;
$font-size-2: 2rem;
$font-size-1562: 1.562rem;
$font-size-1125: 1.125rem;
$font-size-06875: 0.6875rem;
$font-size-25: 2.5rem;
$font-size-09375: 0.9375rem;
$font-size-0625: 0.625rem;
$font-size-11875: 1.1875rem;
$font-size-1875: 1.875rem;
$font-size-125: 1.25rem;
$font-size-15: 1.5rem;
$font-size-0562: 0.562rem;
$font-size-05: 0.5rem;
$font-size-18: 1.8rem;
$font-size-1625: 1.625rem;
$font-size-10625: 1.0625rem;
$font-size-1375: 1.375rem;
$font-size-0125: 0.125rem;
$font-size-0187: 0.187rem;
$font-size-175: 1.75rem;
$font-size-2125: 2.125rem;
$font-size-08: 0.8rem;
$font-size-2062: 2.062rem;
$font-size-0781: 0.781rem;
$font-size-2625: 2.625rem;
$font-size-21875: 2.1875rem;
$font-size-0312: 0.312rem;
$font-size-34: 3.4rem;
$font-size-1687: 1.687rem;
$font-size-12: 1.2rem;
$font-size-2375: 2.375rem;
$font-size-5: 5rem;
$font-size-2: 2rem;
$font-size-3125: 3.125rem;
// =======================================================
//  UN DOCUMENTED FONT SIZES  %
// =======================================================
// These are unique values that are not part of the styleguide. Please avoid unique values where possible
// If you do, add it here and raise an issue in linear
$font-size-percent-40: 40%;
$font-size-percent-50: 50%;
$font-size-percent-65: 65%;
$font-size-percent-70: 70%;
$font-size-percent-75: 75%;
$font-size-percent-80: 80%;
$font-size-percent-85: 85%;
$font-size-percent-90: 90%;
$font-size-percent-93: 93%;
$font-size-percent-95: 95%;
$font-size-percent-100: 100%;
$font-size-percent-105: 105%;
$font-size-percent-110: 110%;
$font-size-percent-115: 115%;
$font-size-percent-120: 120%;
$font-size-percent-130: 130%;
$font-size-percent-140: 140%;
$font-size-percent-150: 150%;
$font-size-percent-160: 160%;
$font-size-percent-170: 170%;
$font-size-percent-200: 200%;
$font-size-percent-210: 210%;
$font-size-percent-220: 220%;
$font-size-percent-240: 240%;
$font-size-percent-250: 250%;
$font-size-percent-300: 300%;
$font-size-percent-320: 320%;
$font-size-percent-400: 400%;
