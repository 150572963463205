@use "@shorthand/design-system/colours" as colours;
@use "@shorthand/design-system/fonts" as fonts;
@use "./footer-variables";

$breakpoint-md: 926px;
$breakpoint-lg: 1100px;
$breakpoint-xl: 1400px;

.PreviewFooter {
  background-color: colours.$preview-footer-2;
  border-top: 2px solid colours.$shorthand-teal;
  bottom: -100px;
  color: white;
  display: block;
  font-family: fonts.$font-family-primary;
  font-size: 14px;
  left: 0;
  position: sticky;

  a {
    color: white;
    text-decoration: none;
  }

  button:focus {
    outline: 0;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    //reset
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    appearance: none;
    background-color: footer-variables.$input-bg;
    border-radius: 4px;
    border: 1px solid colours.$preview-footer-3;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.075);
    color: colours.$white;
    display: inline-block;
    font-family: fonts.$font-family-primary;
    font-size: 1em;
    font-weight: normal;
    height: auto;
    line-height: 1.2;
    margin: 0;
    outline: none;
    padding: 8px 8px 9px;
    resize: vertical;
    vertical-align: top;
    width: 100%;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 16px;
      width: 100%;
    }

    &:focus {
      border-color: colours.$shorthand-teal;
    }
  }

  label {
    color: colours.$preview-footer-1;
    display: block;
    font-family: fonts.$font-family-primary;
    font-size: 0.75em;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 4px;
    margin-top: 0.5em;
    position: relative;
    text-transform: uppercase;
  }

  p,
  span {
    display: block;
    font-variant-numeric: tabular-nums;
    line-height: 1;
    margin: 0;
  }
}

.PreviewFooter--upper {
  background-color: colours.$preview-footer-2;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
}

.PreviewFooter--upper-inner {
  margin-left: 15px;
  width: auto !important;

  @media (max-width: $breakpoint-md) {
    margin-left: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    min-width: 350px;
  }
}

.PreviewFooter-logo {
  @media screen and (max-width: $breakpoint-md) {
    height: 1.5em;
    width: 1.5em;
  }
}

.PreviewFooter--logo-container {
  display: flex;
  margin-right: calc(0.5em + 0.5vw);
  max-width: 2rem;
}

.PreviewFooter--message {
  font: caption;
  font-size: 0.75em;
  line-height: 1.3 !important;
  margin-top: 4px !important;
  opacity: 0.5;
}

.PreviewFooter--content-mobileLandscape {
  display: none;
}

.PreviewFooter--content:last-of-type {
  margin-right: 1em;

  @media (max-width: $breakpoint-md) {
    margin-right: 1em;
  }
}

.PreviewFooter--title {
  font-family: "PT Serif", serif;
  font-size: 1.25em;
  line-height: 1.1 !important;
  margin-top: 0px !important;

  &--mobile {
    @media (min-width: ($breakpoint-md + 1px)) {
      display: none;
    }
  }
}

.PreviewFooter--mobile-header {
  align-items: flex-start;
  column-gap: 1em;
  display: none;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
  row-gap: 1em;

  h3 {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    line-height: 1.3;
    margin-top: 0;
    max-width: 80%;
    text-align: center;
  }
}

.PreviewFooter--col-title {
  align-self: center;
  all: unset;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.2;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  width: 100%;

  @media (max-width: $breakpoint-md) {
    display: none;
  }

  &-mobile {
    background-color: transparent;
    border: none;
    color: colours.$white;
    cursor: pointer;
    font-family: inherit;
    font-weight: 700;
    padding: 0;

    @media screen and (-ms-high-contrast: active) {
      border: 2px solid currentcolor;
    }

    align-items: center;
    font-size: 0.75em;
    justify-content: center;
    text-align: center;

    @media (min-width: ($breakpoint-md + 1px)) {
      display: none;
    }
  }
}

.dropdown-toggle:after {
  border: 4px solid;
  border-left: none;
  border-top: none;
  content: "";
  display: inline-block;
  height: 12px;
  margin-top: -8px;
  opacity: 0.7;
  transform: rotate(45deg);
  transition: all 0.15s ease;
  vertical-align: middle;
  width: 12px;

  @media screen and (max-width: $breakpoint-md) {
    border: 2px solid;
    border-left: none;
    border-top: none;
    content: "";
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    margin-top: -4px;
    opacity: 0.7;
    transform: rotate(45deg);
    transition: all 0.15s ease;
    vertical-align: middle;
    width: 6px;
  }
}

.PreviewFooter--mobile-close {
  cursor: pointer;
  position: absolute;
  right: 1em;
  width: 1em;
}

.PreviewFooter--qr-code {
  flex: unset !important;
  height: 100% !important;
  margin-bottom: 1em;
}

.PreviewFooter--share-btn {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
  color: white;
  display: inline-block;
  flex: 0 !important;
  font-family: fonts.$font-family-primary;
  font-size: 0.75em;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1;
  padding: 9px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;

  &.share-btn-inactive {
    pointer-events: none;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      box-shadow: none;
      color: white;
      cursor: pointer;
      outline: none;
    }
  }
}

.PreviewFooter--btn-secondary,
.PreviewFooter--btn-secondary:not(:disabled):not(.disabled) {
  background-color: transparent;
  border-color: rgba(colours.$gray-light, 0.4);
  color: white;
  margin-left: auto;
  margin-right: 0;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: colours.$gray-lightest;
    border-color: colours.$gray-light;
    color: colours.$gray-darker;

    &:focus {
      background-color: colours.$gray-lightest;
      border-color: colours.$gray-light;
      box-shadow: none;
    }
  }

  &.share-btn-active {
    background-color: colours.$shorthand-teal;
    border-color: colours.$shorthand-teal;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: colours.$gray-dark;
      border-color: rgba(colours.$gray-light, 0.4);

      &:focus {
        color: colours.$gray-dark;
        border-color: rgba(colours.$gray-light, 0.4);
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: rgba(colours.$gray-light, 0.1);
    color: colours.$gray-dark;
    opacity: 0.35;
  }

  &.right {
    margin-left: auto;
    margin-right: 0;
  }
}

.mailtoForm {
  flex: 1;
}

.PreviewFooter--button-group {
  align-items: center;
  column-gap: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  row-gap: 0.5em;
}

.PreviewFooter--customerlogo {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100px;
  width: 100%;
}

.PreviewFooter--help-block {
  background: colours.$shorthand-red;
  border-radius: 4px;
  color: colours.$white;
  display: none;
  font-family: fonts.$font-family-primary;
  font-size: 0.75em !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
  margin-top: 8px !important;
  padding: 8px 8px 5px 8px;
  text-align: center;

  &-success {
    @extend .PreviewFooter--help-block;
    background: colours.$shorthand-teal;
    border-radius: 4px;
    margin-top: 1em !important;
  }
}

.PreviewFooter--chevron {
  display: block;
  width: 1em;

  &-container {
    bottom: 0em;
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0em;
    transition: all 0.15s ease;

    @media (max-width: $breakpoint-md) {
      display: none;
    }

    &-mobile {
      display: none;
      margin-left: 6px;
      width: 1.25em;

      @media screen and (max-width: $breakpoint-md) {
        display: flex;
        margin-top: -0.25em;
      }
    }
  }
}

.PreviewFooter--close {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  margin-right: 0.5em;
  margin-top: 0.5em;

  @media (min-width: $breakpoint-xl) {
    margin-right: 0.75em;
    margin-top: 0.75em;
  }

  &-container {
    bottom: 0em;
    cursor: pointer;
    height: 100%;
    position: absolute;
    right: 0em;
    transition: all 0.15s ease;

    @media (max-width: $breakpoint-md) {
      display: none;
    }

    &-mobile {
      display: none;
      margin-left: 6px;
      width: 1.25em;

      @media screen and (max-width: $breakpoint-md) {
        display: flex;
        margin-top: -0.25em;
      }
    }
  }
}

.PreviewFooter--open-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: fonts.$font-family-primary;
  justify-content: center;
  line-height: 1;
  margin-left: auto;
  margin-right: 0;
}

.PreviewFooter--open-text {
  align-items: center !important;
  cursor: pointer;
  display: none;
  margin-bottom: auto !important;
  margin-right: 1em !important;
  margin-top: auto !important;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width: $breakpoint-md) {
    display: block;
  }

  &-icon {
    align-items: center;
    display: flex;
    margin-right: 0.5em;
  }

  &-icon-container {
    align-items: center;
    display: flex;
  }
}

.PreviewFooter--close-button {
  cursor: pointer;
  padding-right: 1em;
}

.PreviewFooter--grow {
  @media screen and (min-width: ($breakpoint-md + 1px)) {
    flex-basis: 35% !important;
  }
}

.PreviewFooter--row {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 1em;

  &-responsive {
    @extend .PreviewFooter--row;

    @media screen and (max-width: $breakpoint-md) {
      align-items: center !important;
      column-gap: 0.5em;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 0.5em;

      .Layout__col {
        flex-basis: auto;
        width: 100%;
      }
    }
  }
}

.PreviewFooter--interactive-panels {
  margin-right: 0;
  width: 100%;
}

.PreviewFooter--center {
  align-items: center;
}

.PreviewFooter--space-between {
  justify-content: space-between;
}

.PreviewFooter--center-horizontal {
  margin-bottom: auto;
  margin-top: auto;
  padding-bottom: 0;
  padding-top: 0;
}

.DeviceDetect--isIE {
  .PreviewFooter--center-horizontal {
    height: 1.5em;
  }
}

.PreviewFooter--seperator {
  margin: 0.5em;
}

#preview-qr {
  @media screen and (min-width: ($breakpoint-md + 1px)) {
    flex-basis: 40%;
  }

  @media screen and (max-width: $breakpoint-md) {
    text-align: center;
  }
}

#preview-feedback {
  @media screen and (min-width: ($breakpoint-md + 1px)) {
    flex-basis: 60%;
  }
}

//hide form and QR Code in mobile landscape.
@media (max-width: 926px) and (min-aspect-ratio: 13/9) and (hover: none),
  only screen and (min-device-height: 360px) and (min-device-width: 760px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 4) {
  .PreviewFooter--content-mobileLandscape {
    background: colours.$shorthand-red;
    border-radius: 4px;
    color: colours.$white;
    display: block !important;
    font-family: fonts.$font-family-primary;
    font-size: 0.75em !important;
    font-weight: 600 !important;
    margin: auto !important;
    margin-bottom: 1.5em !important;
    padding: 8px 8px 5px 8px;
    text-align: center;
    width: fit-content;
  }

  #preview-feedback,
  #preview-qr {
    .Layout__col {
      display: none !important;
    }
  }

  .PreviewFooter--interactive-panels {
    margin-right: 0;
    width: 100%;
  }
}

.DeviceDetect--isIE .PreviewFooter--open-button {
  display: none;
}
