@use "@shorthand/design-system/colours" as colours;
/*

  -------------

  Theme-able element
*/

.Theme-Scrollpoints-Highlight {
  border: 8px solid colours.$shorthand-teal;
  background-size: contain;
}

.Theme-Scrollpoints-Marker {
  background-color: colours.$shorthand-teal;
  width: 30px;
}

/*
  -------------

*/

.Scrollpoints {
  padding-bottom: 20vh;
}

.Scrollpoints__point {
  padding-top: 50vh;
  padding-bottom: 30vh;
  @media (orientation: portrait) {
    &:first-child {
      padding-top: 85vh;
    }
  }
}

.Scrollpoints__media {
  width: 100%;
  z-index: 0;
  min-height: 100vh;

  &[data-attach="before"] {
    position: absolute;
    top: 0;
    left: 0;
  }

  &[data-attach="during"] {
    position: fixed;
    top: 0;
    left: 0;
  }

  &[data-attach="after"] {
    height: 100vh;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
