html {
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
}

img {
  border-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 0.9em;
}

sub,
sup {
  vertical-align: baseline;
}

canvas,
video {
  display: block;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvbm9ybWFsaXplIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7QUFBQTtFQUVFOzs7QUFHRjtBQUFBO0VBRUUiLCJzb3VyY2VzQ29udGVudCI6WyIvLyBTSU1QTElGSUVEIFJFU0VUIEJBU0VEIE9OIE5PUk1BTElaRSB2NC4xLjFcblxuaHRtbCB7XG4gIC13ZWJraXQtdGV4dC1zaXplLWFkanVzdDogMTAwJTtcbn1cblxuYm9keSB7XG4gIG1hcmdpbjogMDtcbn1cblxuaHIge1xuICBib3gtc2l6aW5nOiBjb250ZW50LWJveDtcbn1cblxuaW1nIHtcbiAgYm9yZGVyLXN0eWxlOiBub25lO1xufVxuXG5wcmUge1xuICBmb250LWZhbWlseTogbW9ub3NwYWNlLCBtb25vc3BhY2U7XG4gIGZvbnQtc2l6ZTogMC45ZW07XG59XG5cbnN1YixcbnN1cCB7XG4gIHZlcnRpY2FsLWFsaWduOiBiYXNlbGluZTtcbn1cblxuY2FudmFzLFxudmlkZW8ge1xuICBkaXNwbGF5OiBibG9jaztcbn1cbiJdfQ== */