@use "@shorthand/design-system/colours" as colours;
.InlineMedia--soundCloud {
    min-height: 1rem;
}
.SoundCloudPlayer--initializing {
  background-color: rgba(colours.$admin-4, 0.5);
  color: colours.$editor;

  &:before {
    content: 'SoundCloud Player is loading...';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
