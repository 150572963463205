@use "@shorthand/design-system/colours" as colours;
.FullSize {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  &--fixedChild {
    overflow: hidden;

    /*
      Mobile Safari hates clip (which is deprecated anyway)
    */
    :not(.DeviceDetect--isiOS) & {
      clip: rect(0, auto, auto, 0);

      // For Animated Image fixed TOMs in Safari see https://github.com/Shorthand/dylan/issues/5120
      &.Theme-Layer-background-viewport-cyclops {
        clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
      }

      // Fixes a clipping issue with split AnimatedImages on small screens
      &[data-background-animation] {
        clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
      }

      // For split TOM videos in Editor - need to be very specific with clip-path so that #3508 doesn't keep reoccuring.
      &.Theme-Layer-background-viewport-video {
        clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
      }
    }

    // Stop the split layouts doubling up, and having overhang between sections - only applied to Editor - added when fixing: https://github.com/Shorthand/dylan/issues/3508
    :not(.DeviceDetect--isIE):not(.DeviceDetect--isEdge) &.InstantImage {
      clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
    }

    /*
      Android Chrome, IE hate clip-path (it works up until certain heights of the clipped element) fix for colours.$full-size-19
      This is only applied to story - adjusted when fixing: https://github.com/Shorthand/dylan/issues/3508
    */
    :not(.DeviceDetect--isAndroid):not(.DeviceDetect--isIE):not(.DeviceDetect--isEdge) & .Theme-BackgroundImage {
      clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
    }

    // iOS fix unwanted lines between sections for: https://github.com/Shorthand/dylan/issues/3508
    .DeviceDetect--isiOS & {
      clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
    }

    // Fix missing background on Win7+IE11 colours.$full-size-2
    .DeviceDetect--isIE & {
      z-index: auto;
    }
  }

  &--scrollChild {
    picture,
    video,
    sh-model {
      height: 100vh;
      max-height: 100%;
      position: sticky;
      top: 0;
    }
  }
}

picture.FullSize--fixedChild {
  // Fix for IE/Edge to make `clip` work
  position: absolute;
}

/***

  Safari 15 has issues with playing videos that are fixed positioned. They think
  they are playing but nothing actually shows up.

  We have to initially load fixed videos as relative for Safari and then swap them 
  over once they have loaded.

***/

// Non Safari and non IE
@supports not (background: -webkit-named-image(i)) {
  .FullSize--fixedChild .FullSize__fixedChild {
    // random fix for missing images in Edge
    z-index: 1;
    position: fixed !important;
    top: 0;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}

// IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .FullSize--fixedChild .FullSize__fixedChild {
    // random fix for missing images in Edge
    z-index: 1;
    position: fixed !important;
    top: 0;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}

// Safari
@supports (background: -webkit-named-image(i)) {
  img.FullSize__fixedChild,
  picture.FullSize__fixedChild,
  sh-model.FullSize__fixedChild,
  div.FullSize__fixedChild {
    // random fix for missing images in Edge
    z-index: 1;
    position: fixed !important;
    top: 0;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  // Fixed videos in safari initially load with relative position
  // but then get given this class which applies the fixed position, etc
  .FullSize--fixedChild video.FullSize__fixedChild--Safari {
    // random fix for missing images in Edge
    z-index: 1;
    position: fixed !important;
    top: 0;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
}

.FullSize--basic {
  display: block;
  width: 100%;
  height: 100%;
}

.FullSize--fullWidth {
  display: block;
  width: 100%;
}

.FullSize--fullHeight {
  display: block;
  height: 100%;
}

.DeviceDetect--isIE .FullSize__fixedChild + picture {
  z-index: 0;
}
