.ObjectFit--cover {
  object-fit: cover;
}

.ObjectFit--contain {
  object-fit: contain;
  object-position: top;
}

.__FauxObjectFit__ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.ObjectFit--hasFauxImg {
  visibility: hidden;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvb2JqZWN0LWZpdCIsInNvdXJjZXMiOlsib2JqZWN0LWZpdC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7OztBQUdGO0VBQ0U7RUFHQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLk9iamVjdEZpdC0tY292ZXIge1xuICBvYmplY3QtZml0OiBjb3Zlcjtcbn1cblxuLk9iamVjdEZpdC0tY29udGFpbiB7XG4gIG9iamVjdC1maXQ6IGNvbnRhaW47XG4gIC8vIFRPRE86IEZvcmNlIHRvcCBwb3NpdGlvbiBmb3IgMmNvbCB0aHVtYnMgaW4gZWRpdG9yXG4gIC8vIFdlIHNob3VsZCBmaW5kIGEgYmV0dGVyIHNvbHV0aW9uIGZvciB0aGF0IGluIHRoZSBmdXR1cmVcbiAgb2JqZWN0LXBvc2l0aW9uOiB0b3A7XG59XG5cbi5fX0ZhdXhPYmplY3RGaXRfXyB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAwO1xuICBsZWZ0OiAwO1xuICByaWdodDogMDtcbiAgYm90dG9tOiAwO1xuICBiYWNrZ3JvdW5kLXJlcGVhdDogbm8tcmVwZWF0O1xuICBiYWNrZ3JvdW5kLXNpemU6IGNvdmVyO1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiBjZW50ZXIgY2VudGVyO1xufVxuXG4uT2JqZWN0Rml0LS1oYXNGYXV4SW1nIHtcbiAgdmlzaWJpbGl0eTogaGlkZGVuO1xufVxuIl19 */