@use "@shorthand/design-system/colours" as colours;
@import "../responsive/breakpoints";
@import "../responsive/orientation";

.Body--hasLightbox {
  // IE11 still has scrollbars with overflow hidden so this makes sure they are hidden when showing
  // a lightbox
  -ms-overflow-style: none;
  overflow: hidden;

  article,
  .HeaderBarContainer {
    z-index: -1;
  }
}

.MediaGallery {
  .Layout {
    .section-thumbnail-container & {
      min-width: 70%;
    }
  }
}

.MediaGallery--Narrow {
  margin: 0 auto;
  .Layout {
    @include media-breakpoint-up(sm) {
      width: 85%;
      min-width: 550px;
    }
    @include media-breakpoint-up(md) {
      width: 70%;
    }
    @include media-breakpoint-up(lg) {
      max-width: 900px;
      width: 50%;
      min-width: 600px;
    }
  }
}

.MediaGallery--Wide {
  margin: 0 auto;
}

.MediaGallery--Full {
  margin: 0 auto;
  .Layout {
    max-width: 100%;
  }
}

.MediaGallery__row {
  display: flex;
  &:first-child {
    // SHOW EXPAND ICON FOR TOUCH DEVICES
    @media (hover: none) and (pointer: coarse) {
      &:after {
        content: "";
        pointer-events: none;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA0hJREFUeNrsnLFrVEEQxufiKZp7QU1hKiHYiQZFLDQBsdPCi6KFCmrlf6aC2BljodhIvGhEJWotXCFWBsXLmSKYc4a3oIZ7vDe77433ZmfggxQXlvvdzre7b2ZfYzAYTAFAH3TEOOotaq/QeL0GAgRlsYaalBpsDJUogkczsCE4Xm8MLIJnoIUBNIBlBfnffskBm8oAbqHeo/ahim4v6HOfUUd94GsDuIE67vF/p1CLNgP94gjqKWrCAPJjBvXBFhG/oFR/ZauwX8y6M/O4AfSD12F8928G8F/P6zA+fxX10gD6ed5l1APUtK3CadouMSbMRdSC+3tX7ABnmWnb3rap3oo5hbmed5N7ItEMkOt5t1B37SQS7nnRAwz1vKg30lzPuxECTxtAH8+7FzpoU1HavoDiFTlvz9MIUNTztKWwhOdNapyB9MOfRT0S8LwuaicMr7H0qbWDOhPWawZwN+orqiXgeXtQOzIAJnUFSDGHeuZginmeJg8k7ztd9T4vJ5K6LyKrzgt/VbXPi2Ej/Rx1EtKa8N+ed0dqNdMQqy6du27mLUgNXOdFZFjQdmNTcLxE28OETekBrb3NABpAA2gALQygATSABtDCAOoHSAf+j6gTBpAfVLelbni6j7GkBaIUQHr8/g7+3Ayl3uQ3kJYkDWBOZBW9G24mHq47wCqfBebVbWn8FdSxugKkB6oHMiDSl6Naw0aA53Wg2FUCuqs2DcNrG6McCRXW6b5EKwNgF/zunnF6VaiF9jbktNKOahBAekFD1j2xQ57wOszPr2hdRNZK9rzt0a4zvLJXYW5/3hWotugtlsJlbVU4Pcnn3KYaDCDf885rgVdGCnM97zrqiT1M8PM8avS5D8qiGZC2HM+rtMWsbgC5nncB9RiUBjeFfXqS1cIrArAR4Hki/Xl1SeEZd2YuOmPngdcArnYGfoL0Mfxr5oIRBbwiM5BeSrPM9LxFiCjyZtWUeZ7MWXg+prQtG2A7trQt8yx8KWZ4IQCphnIN9RAiDyoq/QD+q996kL7R5yCk98iKBo11BvwLVaMWiS/AkJgAPdcqxK85fIfir+aMYhGJPgygAfz/ACUXEPK/viJ+63QS+SII8ac7X2uB2PotwACJk6iKUeximQAAAABJRU5ErkJggg==")
          center no-repeat;
        background-size: 70%;
        display: block;
        position: absolute;
        top: 9px;
        right: 5px;
        background-color: rgba(black, 0.2);
        color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
      }
    }
  }
}

.MediaGallery__row--1 {
  .MediaGallery__cell {
    height: 50vh;
    min-height: 300px;
    .MediaGallery--Narrow & {
      height: 40vh;
    }
    .MediaGallery--Full & {
      height: 60vh;
    }
    @include orientation-portrait {
      .MediaGallery & {
        height: 35vh;
        min-height: 0;
      }
    }
  }
}

.MediaGallery__row--2 {
  .MediaGallery__cell {
    height: 30vh;
    min-height: 200px;
    .MediaGallery--Narrow & {
      height: 20vh;
    }
    .MediaGallery--Full & {
      height: 40vh;
    }
    @include orientation-portrait {
      .MediaGallery & {
        height: 20vh;
        min-height: 0;
      }
    }
  }
}

.MediaGallery__row--3 {
  .MediaGallery__cell {
    height: 25vh;
    min-height: 140px;
    .MediaGallery--Narrow & {
      height: 15vh;
    }
    .MediaGallery--Full & {
      height: 30vh;
    }
    @include orientation-portrait {
      .MediaGallery & {
        height: 15vh;
        min-height: 0;
      }
    }
  }
}

.MediaGallery__row--4 {
  .MediaGallery__cell {
    height: 20vh;
    min-height: 100px;
    .MediaGallery--Narrow & {
      height: 10vh;
    }
    .MediaGallery--Full & {
      height: 20vh;
    }
    @include orientation-portrait {
      .MediaGallery & {
        height: 10vh;
        min-height: 0;
      }
    }
  }
}

.MediaGallery__cell {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  border: none;
  background: none;
  display: block;
  padding: 0;

  &:focus {
    outline: 2px solid black;
  }

  .section-thumbnail-container & {
    min-height: 37vh;
  }
  video,
  img {
    transition: transform 0.15s ease-in-out;
  }

  &.MediaGallery__cell--size1 {
    width: 25%;
  }

  &.MediaGallery__cell--size2 {
    width: 50%;
  }

  &.MediaGallery__cell--size3 {
    width: 100%;
  }

  .Caption--hasOverlay {
    position: absolute;
    bottom: -2em;
    opacity: 0;
    width: 100%;
    transition: 0.15s ease-in-out;
    .Layout {
      max-width: 100%;
      min-width: 0;
      width: 100%;
    }
    p:not(:empty) {
      padding-left: 1em;
      padding-right: 1em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.MediaGallery__cell--click {
  &:hover,
  &:focus {
    body:not(.body-editor) & {
      cursor: zoom-in;
      video,
      img {
        transform: scale(1.03);
      }
    }
    .Caption--hasOverlay {
      bottom: 0;
      opacity: 1;
    }
  }
}

.MediaGallery__cell--non-click {
  &:hover,
  &:focus {
    .Caption--hasOverlay {
      bottom: 0;
      opacity: 1;
    }
  }
}

.MediaGallery--lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(colours.$editor, 0.95);
  backdrop-filter: blur(5px);
  transition: opacity 0.25s ease-out;
  transform: translateY(105%);
  opacity: 0;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  &.MediaGallery--lightbox--open {
    transform: translateY(0);
    opacity: 1;
  }

  .MediaGallery--lightbox__inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    @include media-breakpoint-up(sm) {
      padding-top: 4vw;
      padding-left: 4.5vw;
      padding-right: 4.5vw;
      padding-bottom: 4vw;
    }
  }

  .MediaGallery--lightbox__counter {
    opacity: 0.01;
  }

  .MediaGallery--lightbox__media {
    position: relative;
    width: 100%;
    height: 100%;

    .MediaGallery--lightbox__item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      opacity: 0;
      transition: opacity 0.5s ease-out;

      &.active {
        display: block;
        opacity: 1;
      }

      video:focus {
        border: 1px solid colours.$white;
      }
    }

    .Lazyload__loading {
      display: none;
    }

    .FullSize--fullHeight {
      position: relative;
    }
  }
}
.MediaGallery--lightbox__controls {
  button[data-action] {
    position: absolute;
    z-index: 1000;
    width: 50px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.15s ease;
    -webkit-tap-highlight-color: transparent;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.6;
      }
    }
    &:focus {
      outline: 0;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .MediaGallery__iconLine {
    display: block;
    height: 2px;
    width: 80%;
    position: absolute;
    background-color: white;
    border-radius: 2px;
  }

  button[data-action="close"] {
    top: 0;
    right: 0;
    width: 50px;
    height: 60px;
    z-index: 2;
    @media (hover: none) and (pointer: coarse) and (orientation: landscape) {
      top: 20px;
    }
    .MediaGallery__iconLine {
      width: 60%;
      &:first-child {
        transform: rotate(45deg);
        left: 4px;
      }
      &:last-child {
        transform: rotate(-45deg);
        left: 4px;
      }
    }
  }

  button[data-action="previous"] {
    z-index: 1;
    height: 100%;
    top: 0;
    left: 0;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        margin-left: -5px;
      }
    }
    .MediaGallery__iconLine {
      width: 18px;
      &:first-child {
        transform: rotate(-45deg);
        margin-top: -12px;
        left: 15px;
      }
      &:last-child {
        transform: rotate(45deg);
        left: 15px;
      }
    }
  }

  button[data-action="next"] {
    z-index: 1;
    height: 100%;
    top: 0;
    right: 0;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        margin-right: -5px;
      }
    }

    .MediaGallery__iconLine {
      width: 18px;
      &:first-child {
        margin-top: -12px;
        right: 15px;
        transform: rotate(45deg);
      }
      &:last-child {
        right: 15px;
        transform: rotate(-45deg);
      }
    }
  }
}

.MediaGallery_carousel {
  width: 100%;
  overflow: hidden;
  display: flex;
  .carousel-cell-item {
    background-color: transparent;
    opacity: 0.2;
    margin-right: 15px;
    border: none;
    padding: 0 !important;
  }
}

//if small inner
.carousel-inner-Small {
  margin-bottom: -50px;
  height: 35vh;
}

.carousel-inner-Medium {
  height: 60vh;
}

.carousel-inner-Large {
  height: 85vh;
}
.MediaGallery_carousel::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes fadeInFirst {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes fadeInCaption {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutCaption {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.MediaGallery--Carousel {
  padding: 30px;

  @include media-breakpoint-down(md) {
    padding: 15px;
  }
}
.MediaGallery_carousel {
  .active {
    animation: fadeInFirst 1s;
    opacity: 1;
  }

  .active-caption {
    animation: fadeInCaption 4s;
    opacity: 1;
  }

  .fade-out-captions {
    animation: fadeOutCaption 3s;
    opacity: 0;
  }

  .non-active-fade-out {
    animation: fadeOut 1s;
    opacity: 0.2;
  }

  .MediaGallery__cell:first-child {
    margin-left: 0px;
  }
}
.fade-at-end {
  -webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
  mask-image: linear-gradient(to right, black 90%, transparent 100%);
}

.fade-in-element-slow {
  animation: fadeIn 4s forwards;
}

.fade-in-element-fast {
  animation: fadeIn 1s forwards;
}

.carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100%;
}

.carousel-button {
  display: block;
  background-color: transparent;
  border: none;
  position: absolute;
  padding: 0;
  z-index: 2;
  .carousel-btn-svg {
    height: 40px;
    @include media-breakpoint-down(md) {
      height: 30px;
    }
  }
}

//captions

.carousel-item-caption {
  display: flex;
  justify-content: center;
  text-align: center;

  .ThemeCaption p {
    padding: 0;
  }
  .Theme-Story p:not(.Theme-LeadIn):not(.Theme-Byline) {
    padding: 0;
  }
}

.carousel-captions-Medium,
.carousel-captions-Large {
  padding-left: 5px;
  padding-top: 14px;
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-top: 10px;
  }
}

.long-carousel-caption .caption-editor p {
  text-align: left;
}
.hide-caption {
  visibility: hidden;
}
.carousel-captions-container {
  display: flex;
}

.carousel-captions {
  display: flex;

  .Theme-OverlayedCaption p:not(:empty) {
    padding: 0;
    background-color: transparent;
  }
  .Theme-OverlayedCaption {
    background-color: transparent;
  }
  .Theme-Caption {
    margin: 0;
  }
  .Theme-Caption {
    p {
      padding: 0 !important;
    }
  }
  .Theme-Caption p:not(:empty) {
    @include media-breakpoint-down(md) {
      max-width: 88vw;
      margin-right: 15px;
    }
  }
}

.mobile-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 15px;
  padding-left: 0;
}

.carousel-button-next {
  right: -15px;
}

.carousel-button-back {
  left: -15px;
}

.center-carousel {
  justify-content: center;
  .carousel-button {
    display: none;
  }
  .Theme-OverlayedCaption p:not(:empty) {
    text-align: center;
  }
}

.carousel-inner {
  display: flex;
  overflow: visible;
}

.carousel-inner img {
  width: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.carousel-wrapper {
  display: flex;
  flex-direction: column;
}

.carousel-image-Small {
  height: 100%;
}

.carousel-image-Medium {
  height: 100%;
}

.carousel-image-Large {
  height: 100%;
}

.carousel-item {
  height: 100%;

  @include media-breakpoint-down(sm) {
    width: 90%;
  }
}

@media (orientation: landscape) {
  .carousel-inner-Medium,
  .carousel-inner-Small {
    @include media-breakpoint-down(md) {
      height: 80vh;
    }
  }
}

[dir="rtl"] {
  .MediaGallery_carousel {
    .carousel-cell-item {
      margin-right: 0px;
      margin-left: 15px;
    }

    .carousel-mobile-media {
      margin-right: 0px;
      margin-left: 15px;
      .carousel-image {
        margin-left: 0;
        margin-right: 0px;
      }
    }
  }

  .center-carousel-captions .Theme-OverlayedCaption p:not(:empty) {
    text-align: center;
  }
  .carousel-captions .Theme-OverlayedCaption p:not(:empty) {
    text-align: right;
  }

  .long-carousel-caption .caption-editor p {
    text-align: right;
  }
  .center-carousel-captions {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .mobile-caption,
  .long-carousel-caption {
    margin-left: 15px;
    margin-right: 0px;
  }
}

.MediaGallery_carousel .carousel-mobile-media {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-image {
  height: 100%;
  width: 100%;
}
