// SIMPLIFIED RESET BASED ON NORMALIZE v4.1.1

html {
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
}

img {
  border-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 0.9em;
}

sub,
sup {
  vertical-align: baseline;
}

canvas,
video {
  display: block;
}
