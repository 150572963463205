@import "../../../lib/common/src/vendor/bootstrap/functions";
@import "../../../lib/common/src/vendor/bootstrap/variables";

$grid-breakpoints: (
  xs: 0,
  sm: 620px,
  md: 900px,
  lg: 1100px,
  xl: 1400px,
  xxl: 1750px,
  xxxl: 2000px,
  xxxxl: 2600px,
  4k: 3200px,
  4kplus: 4000px,
);

$container-max-widths: (
  sm: 900px,
  md: 1050px,
  lg: 1060px,
  xl: 1360px,
  xxl: 1560px,
  xxxl: 1760px,
  xxxxl: 2200px,
  4k: 2400px,
  4kplus: 3400px,
);
