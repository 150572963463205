// Orientation responsive utilities

@import './orientation';

@include orientation-portrait {
  .Responsive--hide-portrait {
    display: none !important;
  }
}

@include orientation-landscape {
  .Responsive--hide-landscape {
    display: none !important;
  }
}
