[data-chart-section] {
  padding-bottom: 20vh;

  .Chart__point {
    padding-top: 50vh;
    padding-bottom: 30vh;
    &:first-child {
      padding-top: 0;
    }
  }

  .Theme-Layer-BodyText {
    pointer-events: all;
  }

  .Chart__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Chart__canvas {
    top: 0;
    position: sticky;
    height: 100vh;
    width: 100%;
    padding-top: 10%;

    .Chart__legend {
      position: absolute;
      top: -10px;
      left: 50%;
      background: rgba(255, 255, 255, 0.9);
      border: 2px solid white;
      font-size: 0.9rem;
      transform: translateX(-50%);

      ul {
        list-style: none;
        display: flex;

        li {
          span {
            display: inline-block;
            height: 10px;
            width: 10px;
            margin-right: 3px;
            border-radius: 10px;
          }
        }
      }
    }

    .Chart__inner {
      width: 80%;
      height: 80%;
      margin-left: 10%;

      canvas {
        width: 80%;
        height: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (orientation: portrait) {
      .Chart__inner {
        width: 96%;
        margin-left: 2%;
        canvas {
          width: 96%;
          height: 75%;
        }
      }
    }
  }
}
