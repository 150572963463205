@use "@shorthand/design-system/colours" as colours;
@use "@shorthand/design-system/fonts" as fonts;
@import "../responsive/breakpoints";

.Editor-StoryContainer {
  .Navigation__button {
    &:focus-visible {
      outline: 2px solid colours.$shorthand-teal;
    }
  }
}

.Project-Footer,
.Project-Header {
  max-width: none;
  .ProjectNavigation__SocialIcons {
    position: relative;
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    margin-right: 0;
    margin-top: 0;
  }
  .Theme-Default-Logos {
    pointer-events: none;
  }
  .Theme-NavigationLink {
    user-select: none;
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  :not(.Theme-ProjectNavigation-subItem) > .Theme-NavigationLink {
    &:focus-visible {
      outline: 2px solid inherit;
      border-radius: 2px;
      padding: 0;
      margin: 8px 10px;
    }
  }
}

// HEADER
.Project-Header {
  @include media-breakpoint-down(lg) {
    .Theme-NavigationLink {
      text-align: left;
    }
    .Theme-ProjectNavigation-subMenu {
      padding-left: 1em;
    }
    .Theme-NavigationBarItem:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .Theme-NavigationBar {
      overflow: visible;
    }
    // DROPDOWN
    .Theme-ProjectNavigation-subMenu {
      list-style: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      min-width: max-content;
      max-width: 300px;
      text-align: left;

      .Theme-NavigationBarItem {
        z-index: 1;
      }
      .Theme-ProjectNavigation-subMenu {
        .Theme-NavigationLink {
          font-weight: normal;
          opacity: 0.8;
        }
      }
    }
  }
  ~ #Navigation__hamburgerToggle,
  ~ .Navigation__hamburger {
    display: none !important;
  }

  ~ .Theme-NavigationBar {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

// NAV WITHIN HEADER
.ProjectNavigation--A {
  .Project-Header {
    .Theme-NavigationBar {
      @include media-breakpoint-up(lg) {
        position: relative;
        background-color: transparent !important;
      }
    }
  }
}
// NAV UNDER HEADER
.ProjectNavigation--B {
  @include media-breakpoint-up(lg) {
    .Project-Header--center {
      position: fixed;
      left: 0;
      right: 0;
      .Theme-Navigation-ItemList {
        margin: 0 auto;
      }
    }
  }
}
// BURGER
.ProjectNavigation--C {
  .Project-Header {
    .Theme-Hamburger {
      display: initial !important;
      margin: 0 0 0 15px;
      right: auto;
      top: auto;
      & .hidden {
        display: none !important;
      }
    }
    .Theme-Navigation-ItemList {
      flex-direction: column;
      align-items: flex-start;
    }
    .Theme-NavigationBar {
      position: fixed;
      right: -110%;
      bottom: 0;
      left: auto;
      padding-bottom: 40px;
      overflow: hidden;
      overflow-y: auto;
      max-width: 400px;
    }
    .hasMenu:after {
      display: none !important;
    }
    .Navigation__button {
      display: none;
    }
    .Navigation--isActive {
      right: 0;
      opacity: 1;
    }
    .Theme-NavigationBarItem {
      display: block;
      width: 100%;
      text-align: left;
    }
    .Theme-ProjectNavigation-subMenu {
      position: relative;
      top: auto;
      max-width: 100%;
      left: auto;
    }
  }
}
// NO NAV
.ProjectNavigation--D {
  .Project-Header {
    .Theme-NavigationBar {
      display: none;
    }
  }
}

.Project-Header--left {
  display: flex;
  align-items: center;
  padding-left: 15px;
  min-width: 200px;
  .Theme-Logos:not(:empty) {
    margin-left: 0;
  }
  .Theme-Logo {
    display: inline-flex;
  }
}

.Project-Header--right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  @include media-breakpoint-up(md) {
    min-width: 200px;
  }
  .Theme-SocialIcons {
    position: relative;
    right: auto !important;
    transition: opacity 0.15s ease;
    top: auto;
    margin: 0;
  }
  .Theme-Hamburger {
    background-color: transparent;
    position: relative;
    right: auto;
    margin: 0 0 0 15px;
    top: auto;
  }
}

// FOOTER

.Project-FooterContainer {
  background-color: white;
  border-top: 1px solid rgba(black, 0.1);
}

.Project-Footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .Project-Navigation {
    position: relative;
    top: auto;
    display: flex;
    background-color: transparent;
    max-width: 100%;
    padding: 0;
    right: auto !important;
    transform: none;
    margin: 0;
    opacity: 1;
    justify-content: center;
    z-index: 20;
  }
  .Theme-Navigation-ItemList {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin: 1em auto;
    }
  }
  .Theme-NavigationLink {
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    &.hasMenu {
      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }
  .Theme-ActiveNavigationBarItem .Theme-NavigationLink,
  .Theme-NavigationLink:hover {
    color: inherit;
  }
  .Theme-NavigationBarItem {
    height: auto;
    @include media-breakpoint-up(md) {
      &:not(.hasMenu) {
        align-self: stretch;
        min-height: 100%;
      }
    }
  }
  .Theme-ProjectNavigation-subMenu {
    padding: 0;
    display: flex;
    flex-direction: column;

    .Theme-NavigationLink {
      font-weight: normal;
      opacity: 0.8;
    }
  }

  // NO NAV
  .ProjectNavigation--D & {
    align-items: center;
    .Project-Navigation {
      display: none;
    }
  }
}

.Project-Footer--left {
  padding-left: 15px;
  @include media-breakpoint-up(md) {
    min-width: 200px;
  }
  .Theme-Logos:not(:empty) {
    margin-left: 0;
  }
}

.Project-Footer--center {
  flex: 1;
}

.Project-Footer--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 15px;
  @include media-breakpoint-up(md) {
    min-width: 200px;
  }
  @include media-breakpoint-down(md) {
    &:empty {
      display: none;
    }
  }
  .Theme-SocialIcons {
    position: relative;
    top: auto;
    margin: 0;
    height: 40px;
  }
}

//Project Search

//icon in editor
.project-search-icon-span {
  height: 10px;
  width: 10px;
  color: colours.$gray;
  padding: 5px;
}

.project-search-icon-svg {
  height: 20px;
}

.project-search-button {
  position: relative;
  color: colours.$gray;
  background-color: transparent;
  border: none;
  height: 30px;
  right: 10;
  top: 0;
}

.project-search-btn-tooltip {
  visibility: hidden;
  font-family: fonts.$ui-text-font-family;
  letter-spacing: 0;
  display: block;
  pointer-events: none;
  position: absolute;
  color: colours.$gray-dark;
  background: white;
  padding: 3px 6px;
  white-space: nowrap;
  line-height: 1;
  font-size: fonts.$font-size-0625;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow:
    0 0 0 1px rgba(black, 0.1),
    0 2px 5px rgba(black, 0.25);
  border-radius: 2px;
  text-rendering: optimizeLegibility;
  opacity: 0;
  transition:
    visibility 0s linear 0.15s,
    opacity 0.15s linear;
  top: -5px;
  right: 105%;
}

.project-search-button:hover {
  .project-search-btn-tooltip {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

//Project search sidebar
.project-search-sideBar {
  position: fixed;
  left: auto;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  top: 0;
  right: -600px;
  background: #f1f1f1;
  overflow-x: hidden;
  transition: right 0.5s;
  max-height: 100vh;
  z-index: 2000;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
@media (max-width: 619px) {
  .project-search-sideBar {
    min-width: 100%;
  }
}
@media (min-width: 620px) and (max-width: 1099px) {
  .project-search-sideBar {
    max-width: 400px;
  }
}
@media (max-width: 1099px) {
  .project-search-sideBar {
    max-width: 400px;
  }
}
@media (min-width: 900px) {
  .project-search-sideBar {
    max-width: 400px;
    .project-search-header {
      height: 50px;
    }
  }
}
@media (min-width: 1100px) {
  .project-search-sideBar {
    max-width: 400px;
    .project-search-header {
      height: 60px;
    }
  }
}
.project-search--isActive {
  right: 0;
}

.project-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px 20px;
  //Delete line below once themes changes are merged
  background-color: colours.$gray;
  position: sticky;
  top: 0;
  z-index: 2000;

  .project-search-close-button {
    border: none;
    background-color: transparent;
  }

  .project-search-input-container {
    height: 30px;
    width: 300px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    //Delete line below once themes is merged
    background-color: white;
    &:focus-within {
      outline: 2px solid colours.$shorthand-teal;
    }
    .project-search-icon-svg {
      height: 16px;
      fill: white;
      font-weight: 700;
    }
    .project-search-x-svg {
      height: 100%;
    }
    .project-search-delete-btn {
      background: transparent;
      border: none;
      height: 20px;
      pointer-events: all;
      cursor: pointer;
    }
    .project-search-enter-btn {
      background: colours.$gray;
      border-radius: 5px;
      border: none;
      height: 90%;
      pointer-events: all;
      cursor: pointer;
    }
  }

  .project-search-input {
    border: none;
    font-size: 16px;
    width: 90%;
    padding-left: 5px;

    &:focus {
      outline: none;
    }
  }
}

.project-search-results {
  right: 20px;
}

.project-card-image-div {
  height: 80px;
  width: 150px;
  flex-shrink: 0;
  flex-grow: 0;

  * {
    border: 1px solid #e2e2e2;
    border-radius: 0.5rem;

    background: #eee;
    width: 100%;
    height: 100%;
    max-width: unset;
  }

  img {
    display: block;
    object-fit: cover;
    object-position: center;
  }
}

.project-image-link {
  display: flex;
  flex-flow: column;
  text-decoration: none;
}

.project-card-top {
  display: flex;
}

.project-story-list-item {
  width: 100%;
  list-style-type: none;
  display: flex;
  margin-bottom: 25px;

  .project-story-text-container {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .project-story-text-right-container {
    display: flex;
    flex-direction: column;
    min-height: 80px;
    color: colours.$index-1;
  }

  .project-story-title {
    font-family: fonts.$font-family-primary;
    font-size: 16px;
    font-weight: 700;
    width: 185px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .project-story-description {
    font-size: 16px;
  }

  .project-match-snippet-container {
    font-size: 16px;
    color: colours.$gray;
    padding-top: 5px;

    .search-input-highlight {
      font-weight: bold;
      font-style: italic;
      color: colours.$shorthand-teal;
    }
  }
}
.no-result-list--item span {
  display: block;
  color: colours.$gray;
}

.no-result-list--item {
  font-family: fonts.$font-family-primary;
  list-style: none;
  font-size: 18px;

  .no-results-heading {
    font-weight: 700;
    color: black;
    padding-bottom: 15px;
  }
  .search-options {
    right: -20px;
  }
}
