@use "@shorthand/design-system/colours" as colours;

:root {
  --media-width: 999999px;
}

.InlineMedia {
  margin: 1rem auto;
}

.InlineMedia--image img {
  width: 100%;
  height: auto;
}

.DeviceDetect--isiOS .InlineMedia .plyr--setup {
  max-width: 100%;
}

.InlineMedia--embed,
.MediaSection--embed {
  // STOP EMBEDS WITH CLASHING CLASSHAMES SHOWING WRONG HEIGHT
  // https://github.com/Shorthand/dylan/issues/4986
  min-height: auto !important;
  // MAKE SURE STUFF IS CENTERED
  * {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @media (orientation: portrait) {
    .Theme-TextOverMediaSection & {
      width: 100%;
    }
  }

  // TWEET FALLBACKS
  .twitter-tweet:not(.twitter-tweet-rendered) {
    display: block;
    text-align: left;
    color: colours.$inline-media-1;
    width: 100%;
    margin: 10px auto;
    max-width: 550px;
    border: 1px solid rgb(204, 214, 221);
    background-color: white;
    border-radius: 15px;
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Ubuntu,
      "Helvetica Neue",
      sans-serif;
    padding: 15px 18px;
    font-size: 15px;
    line-height: 1.3;
    p {
      color: black !important;
      font-family: inherit;
      display: block;
      font-size: 19px;
      margin: 0 0 10px !important;
      padding-bottom: 10px;
      line-height: inherit;
      border-bottom: 1px solid rgba(204, 214, 221, 0.5);
    }
    a {
      color: inherit;
      display: initial;
    }
  }
}

.iframely-widget-container {
  text-align: center;
}

.InlineMedia--embed {
  min-height: auto !important;
  @media (orientation: portrait) {
    margin-left: -5px;
    width: calc(100% + 10px);
  }
  .Theme-Layer-ResponsiveMedia & {
    margin: 0;
  }
}

// MAKE SOUNDCLOUD SAME HEIGHT AS EXISTING ONE
.RichEmbed--soundcloud div {
  max-height: 126px;
}
.RichEmbed--pinterest {
  @media (orientation: portrait) {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}
.RichEmbed--tiktok {
  @media (orientation: portrait) {
    margin-left: -10px;
    width: calc(100% + 20px);
  }
}

.crop-uncropped.crop-uncropped {
  display: inline-block;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 900px) {
    .CardCanvasItem & {
      display: block;
      width: fit-content;
      margin: auto;
    }
  }

  picture,
  [data-videoplayer] {
    width: 100%;
    height: 100%;
  }

  img,
  video,
  .InlineMedia & img,
  .InlineMedia & video {
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
  }
}

.crop-circle.crop-circle,
.crop-square.crop-square,
.crop-landscape.crop-landscape,
.crop-portrait.crop-portrait {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: auto;

  &.CardCanvasItem__image img {
    max-height: 100% !important;
  }

  @media screen and (max-width: 900px) {
    .CardCanvasItem & {
      display: block;
      height: 100%;
      width: auto;
      margin: auto;
    }
  }

  aspect-ratio: 1;

  picture,
  [data-videoplayer] {
    width: 100%;
    height: 100%;
  }

  img,
  video,
  .InlineMedia & img,
  .InlineMedia & video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    transform: none;
  }
}

.crop-uncropped.crop-uncropped,
.crop-circle.crop-circle,
.crop-square.crop-square,
.crop-landscape.crop-landscape,
.crop-portrait.crop-portrait {
  @media screen and (min-width: 900px) {
    &.InlineScrollmationImage {
      display: none;
    }
  }

  @media screen and (max-width: 620px) {
    .InlineMedia.media-size-110 & {
      width: min(calc(100% + 50px), var(--media-width) * 1.1) !important;
      max-width: min(calc(100% + 50px), var(--media-width) * 1.1) !important;
      margin-left: calc(0px - min(25px, var(--media-width) * 0.1));
      img {
        transform: none;
      }
    }
  }

  @media screen and (min-width: 620px) {
    .InlineMedia.media-size-110 & {
      width: min(calc(100% + 60px), var(--media-width) * 1.1) !important;
      max-width: min(calc(100% + 60px), var(--media-width) * 1.1) !important;
      margin-left: calc(0px - min(30px, var(--media-width) * 0.1));
      img {
        transform: none;
      }
    }
  }
  @media screen and (min-width: 1100px) {
    .InlineMedia.media-size-110 & {
      width: min(calc(100% + 80px), var(--media-width) * 1.1) !important;
      max-width: min(calc(100% + 80px), var(--media-width) * 1.1) !important;
      margin-left: calc(0px - min(40px, var(--media-width) * 0.1));
      img {
        transform: none;
      }
    }
  }
  .InlineMedia.media-size-100 & {
    width: min(100%, var(--media-width));
  }
  .InlineMedia.media-size-90 & {
    width: min(90%, var(--media-width) * 0.9);
  }
  .InlineMedia.media-size-80 & {
    width: min(80%, var(--media-width) * 0.8);
  }
  .InlineMedia.media-size-70 & {
    width: min(70%, var(--media-width) * 0.7);
  }
  .InlineMedia.media-size-60 & {
    width: min(60%, var(--media-width) * 0.6);
  }
  .InlineMedia.media-size-50 & {
    width: min(50%, var(--media-width) * 0.5);
  }
  .InlineMedia.media-size-40 & {
    width: min(40%, var(--media-width) * 0.4);
  }
  .InlineMedia.media-size-30 & {
    width: min(30%, var(--media-width) * 0.3);
  }
  .InlineMedia.media-size-20 & {
    width: min(20%, var(--media-width) * 0.2);
  }
  .InlineMedia.media-size-10 & {
    width: min(10%, var(--media-width) * 0.1);
  }
}

.crop-circle.crop-circle {
  border-radius: 10000px;
}

.crop-landscape.crop-landscape {
  aspect-ratio: 16/9;
}
.crop-portrait.crop-portrait {
  aspect-ratio: 9/16;
}

.crop-circle,
.crop-square,
.crop-landscape,
.crop-portrait {
  .Lazyload__loading {
    height: 0;
  }
}

/* Ensure corners are property rounded at all screen widths */
.Theme-TwoColumnScrollmationSection .crop-uncropped.crop-uncropped:not(.InlineMedia--image):not(.InlineMedia--image__inner) {
  display: block; /* help keep image and instant centered */
  @media (max-width: 899px) {
    img {
      object-fit: cover;
      height: 100% !important; /* keep image width from getting too wide on mobile */
    }
  }
  @media (min-width: 900px) {
    height: auto;
    width: fit-content;
    margin: auto;
  }
}

/* Ensure bottom corners of primary grid item media are property rounded at all screen widths */
.Grid_image [class*="crop-"] {
  display: block;
}
.body-editor .Grid_image .crop-uncropped.crop-uncropped {
  height: auto;
}
