.PositionSticky {
  position: -webkit-sticky; /* Safari 12 and earlier */
  top: 0px;
  position: sticky;
}

.StickyPolyfillElement--top {
  position: absolute;
  top: 0;
}

.StickyPolyfillElement--stick {
  position: fixed;
  top: 0;
}

.StickyPolyfillElement--bottom {
  position: absolute;
  top: auto !important;
  bottom: 0;
}
