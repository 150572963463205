.InstantImage__img {
  opacity: 1;
  transition: opacity 1000ms ease-out;
  filter: blur(10px);
}

.InstantImage--isLoaded .InstantImage__img {
  opacity: 0;
}

[data-instant-image-container] picture {
  position: absolute;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvaW5zdGFudC1pbWFnZSIsInNvdXJjZXMiOlsiaW5zdGFudC1pbWFnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFOzs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLkluc3RhbnRJbWFnZV9faW1nIHtcbiAgb3BhY2l0eTogMTtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAxMDAwbXMgZWFzZS1vdXQ7XG4gIGZpbHRlcjogYmx1cigxMHB4KTtcbn1cblxuLkluc3RhbnRJbWFnZS0taXNMb2FkZWQgLkluc3RhbnRJbWFnZV9faW1nIHtcbiAgb3BhY2l0eTogMDtcbn1cblxuW2RhdGEtaW5zdGFudC1pbWFnZS1jb250YWluZXJdIHBpY3R1cmUge1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG59XG4iXX0= */