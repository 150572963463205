.PositionSticky {
  position: -webkit-sticky; /* Safari 12 and earlier */
  top: 0px;
  position: sticky;
}

.StickyPolyfillElement--top {
  position: absolute;
  top: 0;
}

.StickyPolyfillElement--stick {
  position: fixed;
  top: 0;
}

.StickyPolyfillElement--bottom {
  position: absolute;
  top: auto !important;
  bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvcG9zaXRpb24tc3RpY2t5Iiwic291cmNlcyI6WyJzdGlja3kuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLlBvc2l0aW9uU3RpY2t5IHtcbiAgcG9zaXRpb246IC13ZWJraXQtc3RpY2t5OyAvKiBTYWZhcmkgMTIgYW5kIGVhcmxpZXIgKi9cbiAgdG9wOiAwcHg7XG4gIHBvc2l0aW9uOiBzdGlja3k7XG59XG5cbi5TdGlja3lQb2x5ZmlsbEVsZW1lbnQtLXRvcCB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAwO1xufVxuXG4uU3RpY2t5UG9seWZpbGxFbGVtZW50LS1zdGljayB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgdG9wOiAwO1xufVxuXG4uU3RpY2t5UG9seWZpbGxFbGVtZW50LS1ib3R0b20ge1xuICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gIHRvcDogYXV0byAhaW1wb3J0YW50O1xuICBib3R0b206IDA7XG59XG4iXX0= */