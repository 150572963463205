.Size__xxsmall {
  font-size: 0.75rem;
}

.Size__xsmall {
  font-size: 1rem;
}

.Size__small {
  font-size: 1.2rem;
}

.Size__normal {
  font-size: 1.4rem;
}

.Size__large {
  font-size: 2rem;
}

.Size__xlarge {
  font-size: 3rem;
}

.Size__xxlarge {
  font-size: 5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvdGV4dC1zaXplIiwic291cmNlcyI6WyJ0ZXh0LXNpemUuc2NzcyIsIl9jb25maWcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFLFdDSlM7OztBREdYO0VBQ0UsV0NKUzs7O0FER1g7RUFDRSxXQ0pTOzs7QURHWDtFQUNFLFdDSlM7OztBREdYO0VBQ0UsV0NKUzs7O0FER1g7RUFDRSxXQ0pTOzs7QURHWDtFQUNFLFdDSlMiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuL2NvbmZpZyc7XG5cbkBlYWNoICRuYW1lLCAkc2l6ZSBpbiAkdGV4dC1zaXplcyB7XG4gIC5TaXplX18jeyRuYW1lfSB7XG4gICAgZm9udC1zaXplOiAkc2l6ZTtcbiAgfVxufVxuIiwiJHRleHQtc2l6ZXM6IChcbiAgeHhzbWFsbDogMC43NXJlbSxcbiAgeHNtYWxsOiAxcmVtLFxuICBzbWFsbDogMS4ycmVtLFxuICBub3JtYWw6IDEuNHJlbSxcbiAgbGFyZ2U6IDJyZW0sXG4gIHhsYXJnZTogM3JlbSxcbiAgeHhsYXJnZTogNXJlbSxcbik7Il19 */