@import "../responsive/breakpoints";

.MediaRenderer__textBlocks {
  // There's a global rule that sets position on everything. That creates extra stacking contexts which we don't want here.
  position: unset !important;
  z-index: 1;
}

.MediaRenderer__textBlocksInner_slowest {
  //transition speed === 2
  min-height: 200vh;
  // There's a global rule that sets position on everything. That creates extra stacking contexts which we don't want here.
  position: unset !important;

  &:first-child {
    min-height: 205vh;
  }

  // IE11 and Edge don't like negative top margins so we reset it just for them
  .DeviceDetect--isIE &:first-child,
  .DeviceDetect--isEdge &:first-child {
    margin-top: 0;
  }

  &:last-child {
    min-height: 280vh;
  }

  /// Actual text content:

  &:first-child > div {
    padding-top: 200vh;
  }

  & > div {
    min-height: 200vh;
    background: transparent;
    z-index: 1;

    // Prop up each Slate section so that there is only ever one on
    // screen at once (should add up to around 200vh)
    padding-top: 270vh;
    padding-bottom: 40vh;
  }

  /// /Action text content
}
.MediaRenderer__textBlocksInner {
  //transition speed === 1
  min-height: 105vh;
  // There's a global rule that sets position on everything. That creates extra stacking contexts which we don't want here.
  position: unset !important;

  &:first-child {
    min-height: 45vh;
  }

  // IE11 and Edge don't like negative top margins so we reset it just for them
  .DeviceDetect--isIE &:first-child,
  .DeviceDetect--isEdge &:first-child {
    margin-top: 0;
  }

  &:last-child {
    min-height: 160vh;
  }

  /// Actual text content:

  &:first-child > div {
    padding-top: 80vh;
  }

  & > div {
    min-height: 100vh;
    background: transparent;
    z-index: 1;

    // Prop up each Slate section so that there is only ever one on
    // screen at once (should add up to around 200vh)
    padding-top: 170vh;
    padding-bottom: 40vh;
  }
}

.MediaRenderer__textBlocksInner_fastest {
  // transition speed === 0.35
  min-height: 100vh;
  // There's a global rule that sets position on everything. That creates extra stacking contexts which we don't want here.
  position: unset !important;

  &:first-child {
    min-height: 40vh;
  }

  // IE11 and Edge don't like negative top margins so we reset it just for them
  .DeviceDetect--isIE &:first-child,
  .DeviceDetect--isEdge &:first-child {
    margin-top: 0;
  }

  &:last-child {
    min-height: 130vh;
  }

  /// Actual text content:

  &:first-child > div {
    padding-top: 150vh;
  }

  & > div {
    min-height: 150vh;
    background: transparent;
    z-index: 1;

    // Prop up each Slate section so that there is only ever one on
    // screen at once (should add up to around 200vh)
    padding-top: 150vh;
    padding-bottom: 40vh;
  }

  /// /Action text content
}

.MediaRenderer__fixedCaption {
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;

  &.MediaRenderer__captionDisplay {
    transition: 200ms ease-in-out opacity;
    * {
      transition: inherit;
    }
  }
}

.MediaRenderer__fixedCaption--icon {
  // HIDE BY DEFAULT
  display: none;
  background: transparent;
  border: none;
  z-index: 101;
  opacity: 0.8;
  padding: 0;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    opacity: 1;
  }
  .ico-info {
    background-color: rgba(black, 0.5);
    display: block;
    fill: white;
    width: 30px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(black, 0.5);
  }
}
