@use "@shorthand/design-system/colours" as colours;
@use "@shorthand/design-system/fonts" as fonts;
@import "../responsive/breakpoints";

$plyr-color-main: #fff !default;

.video-icon-large {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border: none;
  border-radius: 100%;
  box-shadow: 0 1px 1px transparentize(#000, 0.85);
  color: white;
  transition: all 0.3s ease;
  width: 50px;
  height: 50px;

  background: rgba(black, 0.4);

  @include media-breakpoint-up(md) {
    width: 100px;
    height: 100px;
  }
  @include media-breakpoint-up(xxl) {
    width: 150px;
    height: 150px;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    /* outline: 1px dotted transparentize(white, 0.5); */
    background-color: rgba(black, 0.8);
  }

  svg {
    display: block;
    fill: currentColor;

    position: absolute;
    top: 30%;
    left: 35%;
    width: 20px;
    height: 20px;

    @include media-breakpoint-up(md) {
      top: 25%;
      left: 30%;
    }
    @include media-breakpoint-up(xxl) {
      top: 30%;
      left: 35%;
    }
    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
    }
    @include media-breakpoint-up(xxl) {
      width: 60px;
      height: 60px;
    }
  }
}

.Videoplayer {
  iframe,
  embed,
  object {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: none;
  }

  video {
    background-color: black;
    width: 100%;
    height: auto;
  }
}

.video-stopped .video-play {
  display: block;
}

.video-error .video-error-block {
  display: block;
}

.Videoplayer--responsive {
  position: relative;

  overflow: hidden;

  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

@media (max-aspect-ratio: 1/1) {
  .Videoplayer--portraitOnlyResponsive {
    position: relative;

    overflow: hidden;

    max-width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
}

.video-error-container {
  position: absolute;
  padding: inherit;
  top: 10px;
  right: 10px;
}
.video-error-block {
  background: black;
  opacity: 0.8;
  transition: all 0.3s ease;
  border-radius: 4px;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
  gap: 0.3em;
  span {
    color: white;
    font-size: 0.6em;
    font-family: fonts.$font-family-primary;
    font-weight: 700;
  }
  svg {
    fill: white;
    display: inline;
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }
}

[data-lazyload-video] {
  opacity: 0;
}

[data-videoplayer] {
  transition: opacity 0.25s ease-out;
}


.ReplayButton {
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0.8;
  margin: 0;
  padding: 0.7em 1.3em;
  font-family: fonts.$font-family-primary;
  font-weight: 700;
  font-size: 0.575em;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid colours.$gray;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

