@import "../responsive/orientation";
@import "../responsive/breakpoints";

.SplitLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include orientation-landscape() {
    .FullSize--fullHeight {
      min-height: 100vh;
    }

    & [class*="Layer--"].SplitLayout--media {
      height: initial;
    }
  }

  @include orientation-portrait() {
    .FullSize--fullHeight {
      min-height: 50vh;

      .DeviceDetect--isiOS10 &.Layer--one {
        padding-top: 30vh !important;
        padding-bottom: 30vh !important;
      }
    }
  }

  & [class*="Layer--"] {
    position: relative;
    height: 50%;
    width: 100%;
    min-height: auto;
  }

  @include orientation-portrait() {
    &.SplitLayout--columnReverse {
      flex-direction: column-reverse;
    }

    .DeviceDetect--isiOS10 & {
      .FullSize--scrollChild {
        height: 50vh;
        width: 100%;
      }
    }
  }

  @include orientation-landscape() {
    flex-direction: row;

    & [class*="Layer--"] {
      height: 100%;
      min-height: 100vh;
      width: 50%;
    }

    &.SplitLayout--rowReverse {
      flex-direction: row-reverse;
      // Fix split sections picture width produceing 1px gap issue on teh right edge
      .SplitLayout--media > picture {
        clip-path: inset(-0.5px);
      }
    }

    // IE shrinks Animated Image fixed media (which is a layer within layer which probably matters)
    .DeviceDetect--isIE & [class*="Layer--"] [class*="Layer--"] {
      width: 100%;
    }

    // Fix image overhang on iOS - this works for iPads & iPhones - part of: https://github.com/Shorthand/dylan/issues/3508
    .DeviceDetect--isiOS & {
      height: calc(100%) !important;
    }

    // Hack to make Split TOM landscape images/videos have a good height in iOS 10
    .DeviceDetect--isiOS10 & {
      .FullSize--fixedChild:not(.FullSize--scrollChild) {
        height: auto;
        clip: unset !important;
        clip-path: unset !important;

        picture {
          height: 1500vh;
        }

        // Overlays don't seem to want to get the full height of long TOMs in iOS10
        // in landscape
        .Theme-Overlay {
          display: none !important;
        }
      }

      .FullSize--scrollChild {
        video {
          height: 100vh;
        }
      }
    }
  }

  & [class*="Layer--"].SplitLayout--media {
    min-height: 50vh;
    // Fix split sections picture width produceing 1px gap issue on the bottom or top edge
    & > picture {
      clip-path: inset(-0.5px);
    }
    // Fix problem with split AI & BG scrolls on small screens
    &[data-background-animation] {
      @include orientation-portrait() {
        height: 50vh;
      }
    }
    &.FullSize--fixedChild {
      overflow: hidden;

      &.Theme-Layer-background-viewport-cyclops {
        clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
      }

      // Fix unwanted line between sections on Split Layer TOMs in iOS see: https://github.com/Shorthand/dylan/issues/3508
      .DeviceDetect--isiOS & html:not(.DeviceDetect--isiOS10) {
        clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
        overflow: unset;
      }
    }

    // Hack to support portrait split TOMs in iOS10
    .DeviceDetect--isiOS10 &.FullSize--scrollChild {
      padding-bottom: 0 !important;
      .FullSize {
        padding-bottom: 0 !important;
      }
    }

    .DeviceDetect--isFirefox & {
      picture {
        clip: rect(0, auto, auto, 0);
      }
    }

    // Without overflow hidden the overlays would be sticking out
    // with negative edges
    .FullSize {
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;

      // Hack to support landscape split TOMs in iOS10
      .DeviceDetect--isiOS10 & {
        padding-bottom: 1500vh;
      }
    }
  }
}

header.SplitLayout .FullSize--scrollChild picture.Theme-Item-InstantImage {
  position: absolute;
  top: 0;
}

// Overrides for Bootstrap's excessive use of !important for
// toggling section visibility

@include media-breakpoint-up(md) {
  .SplitLayout.Display--md-block {
    display: flex !important;
  }
}

// Hack for title: in portrait with image top, we never want the
// picture to be DisplayContainerHeight as you'll never see the bottom half
// of the image.
@include orientation-portrait() {
  .Theme-TitleSection.SplitLayout--columnReverse .Theme-BackgroundImage,
  .Theme-TitleSection.SplitLayout--columnReverse .Theme-BackgroundVideo,
  .Theme-TitleSection.SplitLayout--columnReverse .Theme-BackgroundModel {
    video,
    picture,
    img {
      top: auto;
      height: 50vh;
    }
  }
}
