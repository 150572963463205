.Layer--two.Layer--overlay {
  z-index: 101;
  pointer-events: none;

  .Theme-OverlayedCaption {
    pointer-events: auto;
  }
}

.Layer--one {
  z-index: 100;

  .Theme-BackgroundScrollmationSection > & {
    overflow: hidden;
  }
}

.Layer--two {
  z-index: 90;
}

.Layer--three {
  z-index: 80;
}

.Layer--four {
  z-index: 70;
}

.Layer--five {
  z-index: 60;
}

.Layer--six {
  z-index: 50;
}

.Layer--seven {
  z-index: 40;
}

.Layer--eight {
  z-index: 30;
}

.Layer--nine {
  z-index: 20;
}

.Layer--ten {
  z-index: 10;
}
