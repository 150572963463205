.MediaRenderer__textBlocks {
  margin-top: -100vh;
  pointer-events: none;
}
sh-background-transition .Theme-OverlayedCaption {
  display: none;
}

.Theme-Layer-BodyText {
  pointer-events: all;
}

/* First frame is preset with z-index 1 so that it sits on top
 * until the code finishes loading
 */
sh-background-transition > :first-child {
  z-index: 1;
}

/**
 * This deals with the occasional 1px gap at the bottom of the reveal section due to floating-point heights
 */
sh-background-transition {
  margin-bottom: -0.5px;
}
