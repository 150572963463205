@use "@shorthand/design-system/colours" as colours;

@keyframes footnoteAppearUp {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes footnoteAppearDown {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.Theme-Story {
  .Theme-Layer-BodyText-Highlight {
    background-color: rgba(colours.$shorthand-teal, 0.2);
    position: relative;
    display: inline;
    border-radius: 5px;
    padding-left: 5px;

    &:hover {
      background-color: rgba(colours.$shorthand-teal, 0.5);
    }

    .Theme-Layer-BodyText-Highlight--Icon {
      margin: 0 5px 6px 5px;
      top: -1px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      color: white;
      font-family: sans-serif;
      background-color: black;
      user-select: none;
      &:after {
        content: "i";
      }
    }
  }

  .Theme-Footnote {
    z-index: 1000;
    position: fixed;
    height: auto;
    overflow: visible;
    display: none;
    opacity: 1;
    width: max-content;
    white-space: normal;
    background-color: rgba(white, 0.95);
    color: colours.$editor;
    font-size: 0.8em;
    font-family: sans-serif;
    line-height: 1.5;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    max-width: 300px;
    box-shadow: 0 3px 10px 6px rgba(black, 0.1), 0 0 0 1px rgba(black, 0.1);
    p {
      padding: 15px;
      margin: 0;
      font-family: inherit;
      line-height: 1.5;
      max-height: 30vh;
      overflow: hidden;
      overflow-y: auto;
      &:only-child:empty:after {
        display: block;
        padding: 15px;
        content: "Annotation is empty";
        opacity: 0.6;
        font-style: italic;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: rgba(white, 0.95) transparent transparent transparent;
      z-index: 1;
    }

    &:before {
      // EXTRA AREA FOR HOVER
      content: "";
      position: absolute;
      top: 100%;
      height: 5px;
      width: 100%;
      z-index: -1;
      left: 0;
    }

    // Balloon is above highlight
    &.Theme-Position-Bottom {
      animation: footnoteAppearUp 0.15s ease-out;
      &:after {
        top: 100%;
      }
    }

    // Balloon is below highlight
    &.Theme-Position-Top {
      animation: footnoteAppearDown 0.15s ease-out;
      &:after {
        top: unset;
        bottom: 100%;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent rgba(white, 0.95) transparent;
      }
      &:before {
        bottom: 100%;
        top: unset;
      }
    }

    a {
      color: colours.$editor;
      text-decoration: underline;
    }

    @media (min-width: 500px) {
      max-width: 400px;
    }

    @media (min-width: 620px) {
      max-width: 450px;
    }

    @media (min-width: 1100px) {
      max-width: 550px;
    }

    @media (min-width: 1400px) {
      max-width: 550px;
    }

    @media (min-width: 1750px) {
      max-width: 680px;
    }
  }
}

/* DARK STYLES */

.Theme-Section-Dark {
  .Theme-Layer-BodyText-Highlight {
    background-color: rgba(colours.$shorthand-teal, 0.2);
    &:hover {
      background-color: rgba(colours.$shorthand-teal, 0.5);
    }
    .Theme-Layer-BodyText-Highlight--Icon {
      background-color: white;
      color: black;
    }
  }
}
