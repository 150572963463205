@mixin orientation-portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin orientation-landscape {
  @media (orientation: landscape) {
    @content;
  }
}