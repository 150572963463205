@import "../responsive/orientation";
@import "../responsive/breakpoints";

.BackgroundVideo__fallback {
  position: absolute;
  top: 0;
  height: 100%;
}

/* Double definition to make this rule a _litle_ bit more specific for the cascade. */
.BackgroundVideo__sticky.BackgroundVideo__sticky {
  top: 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  clip-path: inset(-0.5px);

  @include orientation-portrait() {
    .SplitLayout & {
      height: 50vh;
    }
  }

  // The position sticky polyfill does some weird stuff for
  // videos in IE
  .SplitLayout--media &.StickyPolyfillElement--top,
  .SplitLayout--media &.StickyPolyfillElement--bottom {
    width: 100%;
    overflow: hidden;
  }

  .SplitLayout--media &.StickyPolyfillElement--stick {
    width: 50%;
    overflow: hidden;
  }
}
