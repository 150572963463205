.InstantImage__img {
  opacity: 1;
  transition: opacity 1000ms ease-out;
  filter: blur(10px);
}

.InstantImage--isLoaded .InstantImage__img {
  opacity: 0;
}

[data-instant-image-container] picture {
  position: absolute;
}
