@import "../responsive/breakpoints";

:root {
  --corner-multiplier: 1;

  @media screen and (max-width: 400px) {
    --corner-multiplier: 0.25;
  }

  @media screen and (max-width: 600px) {
    --corner-multiplier: 0.4;
  }
}

.Core--rootElement,
.Core--rootElement * {
  position: relative;
  box-sizing: border-box;
}

canvas,
video {
  display: block;
}

sub {
  text-decoration: inherit;
}

sup {
  text-decoration: inherit;
}

.Core--clearfix {
  overflow: auto;
}

.Core--invisible {
  visibility: hidden;
  // take the element out of flow to 'remove' it more completely from the page
  position: absolute;
}

.Core--pointerEventsNone {
  pointer-events: none;
}

//Hide inline scrollmation for wide screens in preview and publish
@include media-breakpoint-up(md) {
  .Core--md-srOnly {
    display: none;
  }
}
