@use "@shorthand/design-system/colours" as colours;
@import "../responsive/breakpoints";

@mixin captionIcon {
  .MediaRenderer__fixedCaption--div {
    z-index: 110;
    position: absolute;
    top: 50px;
    padding-top: 3px;
    bottom: 45px;
    right: 2px;
    min-height: auto !important;
    pointer-events: none;
  }
  .MediaRenderer__fixedCaption--inner {
    position: sticky;
    top: 96vh;
    pointer-events: auto;
  }

  .MediaRenderer__fixedCaption--inner-editor {
    position: sticky;
    top: 86vh;
    pointer-events: auto;
    .body-focus-mode & {
      top: 86vh;
    }
  }

  .MediaRenderer__fixedCaption--icon {
    display: block;
    right: 1em;
    bottom: 30px;

    &:focus-visible {
      &:focus-within {
        .ico-info {
          outline: 2px solid colours.$shorthand-teal;
        }
      }
    }
  }
}

.Theme-BackgroundScrollmationSection {
  // SHOW ICON THAT REVEALS CAPTIONS ON CLICK
  @include media-breakpoint-down(sm) {
    @include captionIcon();
  }
  .Theme-OverlayedCaption {
    @include media-breakpoint-down(sm) {
      max-width: 0;
      overflow: hidden;
    }
    @include media-breakpoint-up(md) {
      opacity: 0;
      will-change: opacity;
      p:not(:empty) {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
  &.Theme-BodyTextColumn-Left {
    .Theme-OverlayedCaption {
      @include media-breakpoint-up(md) {
        left: auto !important;
        max-width: 30%;
        right: 0;
        .focus-mode & {
          right: 225px;
        }
      }
    }
  }
  &.Theme-BodyTextColumn-Center {
    // SHOW ICON THAT REVEALS CAPTIONS ON CLICK
    @include media-breakpoint-down(lg) {
      @include captionIcon();
    }
    .Theme-OverlayedCaption {
      @include media-breakpoint-down(xl) {
        max-width: 0;
        overflow: hidden;
      }
      @include media-breakpoint-up(xl) {
        left: auto !important;
        max-width: 25%;
        right: 0;
        .focus-mode & {
          right: 225px;
        }
      }
    }
  }
  &.Theme-BodyTextColumn-Right {
    .Theme-OverlayedCaption {
      @include media-breakpoint-up(md) {
        right: auto;
        max-width: 30%;
      }
    }
  }

  .BackgroundScrollmationItem__caption {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1rem;
    color: white;
    background-color: rgba(colours.$editor, 0.5);
  }

  .Scrollmation-portrait--Caption-outer {
    width: 320px;
    right: 1em;
    bottom: 0;
    position: absolute;
    max-height: 50vh;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    z-index: 12;
    color: white;
    background-color: rgba(colours.$editor, 0.9);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: 0 5px 10px -4px rgba(black, 0.5);
  }

  .MediaRenderer__Scrollmation-portrait--Caption {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .Scrollmation-portrait-caption-header {
    display: flex;
    align-items: center;
    padding: 20px 20px 15px;
    color: white;
    justify-content: space-between;
    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
    }
    .btn-close {
      background: transparent;
      border: none;
      position: absolute;
      right: 8px;
      top: 8px;
      opacity: 0.6;
      cursor: pointer;
      &:hover,
      &:focus-visible {
        opacity: 1;
      }
    }
    .ico-close {
      display: block;
      fill: white;
      width: 16px;
    }
  }

  .Scrollmation-portrait-captions {
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0 20px;
    margin: 0;
  }

  .Scrollmation-portrait-caption {
    font-family: inherit;
    padding-bottom: 15px;
    display: flex;
    + .Scrollmation-portrait-caption {
      border-top: 1px solid rgba(white, 0.1);
      padding-top: 15px;
    }

    .InlineMedia--image__inner {
      max-width: 28%;
      margin-right: 15px;
      picture {
        position: sticky;
        top: 0;
      }
      img {
        display: block;
        width: 100%;
      }
    }

    small {
      font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
      display: block;
      text-transform: uppercase;
      opacity: 0.7;
      line-height: 1;
      margin-bottom: 0.5em;
      font-size: 10px;
      font-weight: 500;
    }
    p {
      font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
      line-height: 1.4 !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.BackgroundScrollmationColumn {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media not print {
  .BackgroundScrollmation {
    top: 0;
    width: 100%;
    height: 100vh;
    /* children can be oversized in IE, causing horiontal scroll */
    overflow: hidden;
  }

  .BackgroundScrollmationItem {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: 0;
    transition: opacity 1ms linear;

    .BackgroundScrollmation--fadeFast & {
      transition: opacity 200ms ease-in-out;
    }

    .BackgroundScrollmation--fadeSlow & {
      transition: opacity 700ms ease-in-out;
    }
  }

  .BackgroundScrollmationItem--isPrimed {
    display: block;
  }

  .BackgroundScrollmationItem--isActive {
    opacity: 1;
    @include media-breakpoint-up(md) {
      .Theme-OverlayedCaption {
        opacity: 1;
      }
    }
  }

  .BackgroundScrollmationItem__image,
  .BackgroundScrollmationItem img {
    clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
    display: block;
    width: 100%;
    height: 100%;
  }

  .BackgroundScrollmation {
    &[data-attach="before"] {
      position: absolute;
      top: 0;
      left: 0;
    }

    &[data-attach="during"] {
      position: fixed;
      top: 0;
      left: 0;
    }

    &[data-attach="after"] {
      height: 100vh;
      position: absolute;
      left: 0;
      top: unset;
      bottom: 0;
    }
  }
}

//override z-index in order to make caption links clickable
.Theme-BackgroundScrollmationSection {
  .Layout__col {
    z-index: 101;
  }
}

.Theme-BackgroundScrollmationSection > .Layer--one {
  z-index: auto;
}
