@import "../responsive/breakpoints";

@media not print {
  .CardCanvas {
    top: 110px;
    padding: 0 !important;
    margin: 60px 0 30px 0;

    &.StickyPolyfillElement--stick {
      top: 50px;
    }
  }

  .CardCanvas__itemHeightReference {
    position: absolute;
    top: 0;
    /* viewport height - header height - top/bottom margins */
    height: calc(100vh - 60px - 4rem);
    // makes it easier to inspect via dev tools
    width: 3px;
  }

  .CardCanvasItem {
    position: absolute !important;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 1ms linear;

    picture,
    img {
      // overide editor setting bg color on picture
      background-color: transparent !important;
      display: block;
    }
  }

  /** show element, but keep invisible */
  .CardCanvasItem--isPrimed {
    z-index: 10;
  }

  .CardCanvasItem--isActive {
    z-index: 20;
    opacity: 1;
  }

  .CardCanvasItem__image {
    width: 100%;
    text-align: center;

    img {
      display: block;
      object-fit: contain;
      margin: 0 auto;
      width: 100%;

      .DeviceDetect--isIE & {
        width: auto;
        height: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .CardCanvasItem__caption {
    margin: 0 auto;
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    .CardCanvas--tworowContainer {
      .CardCanvas__canvasColumn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        max-width: 100%;
      }

      .CardCanvas {
        height: 35vh !important;
        top: 40px;
        margin-top: 0;
      }

      .CardCanvas__itemHeightReference {
        height: 100%;
        width: 100%;
      }

      .CardCanvasItem,
      .CardCanvasItem__image,
      .Lazyload__loading,
      .Lazyload {
        height: 100%;
        img {
          object-fit: scale-down;
        }
      }

      .CardCanvasItem {
        padding: 4px;
      }

      .CardCanvasItem__caption {
        position: absolute;
        right: 0;
        bottom: 4px;
        left: 0;
        font-size: 16px;
      }

      .CardCanvas__smallItemCaption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

@media (min-width: 900px) {
  .shorthand-shadow-article:not(.DeviceDetect--isEdge):not(.DeviceDetect--isIE) .CardCanvasItem__image img {
    max-width: 750px;
    max-height: 75vh !important;
  }
}
