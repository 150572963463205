body:not(.body-editor) {
  .Theme-Layer-TextBlock {
    .InlineElement--Table {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
  }
}

.InlineElement--Table {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    text-align: left;
    vertical-align: top;

    table-layout: auto;
    min-width: 100% !important;
  }

  p {
    min-height: 1em;
  }

  .InlineMedia {
    text-align: inherit;
  }

  th,
  td {
    vertical-align: inherit;
    text-align: inherit;
    border: 1px solid;
    border-color: inherit;
    height: 2.5em;
    min-width: 2em;

    p {
      font-family: inherit;
      margin: 0;
      padding: 0;
      color: inherit !important;

      + p {
        margin-top: 0.5em;
      }
    }
  }
  .hasCustomFont {
    td,
    th {
      font-family: inherit;
    }
  }
}

.InlineElement--Table::-webkit-scrollbar {
  -webkit-appearance: none;
}

.InlineElement--Table::-webkit-scrollbar:vertical {
  width: 11px;
}

.InlineElement--Table::-webkit-scrollbar:horizontal {
  height: 11px;
}

.InlineElement--Table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #fff; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.InlineElement--Table::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

@media (max-width: 743px) {
  .InlineElement--Table {
    .InlineMedia img {
      min-width: 100%;
    }
  }
}
