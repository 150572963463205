.TransitionIn--fade {
  opacity: 0;
  transition: opacity 900ms cubic-bezier(0.86, 0.01, 1, 1);
}

.TransitionIn--up {
  transform: translateY(5vh);
}

.TransitionIn--fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.TransitionIn--fadeChildren > * {
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-delay: 300ms;
}
.TransitionIn--fadeChildren > *.TransitionIn--fadeIn {
  opacity: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvdHJhbnNpdGlvbi1pbiIsInNvdXJjZXMiOlsidHJhbnNpdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5UcmFuc2l0aW9uSW4tLWZhZGUge1xuICBvcGFjaXR5OiAwO1xuICB0cmFuc2l0aW9uOiBvcGFjaXR5IDkwMG1zIGN1YmljLWJlemllcigwLjg2LCAwLjAxLCAxLCAxKTtcbn1cblxuLlRyYW5zaXRpb25Jbi0tdXAge1xuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoNXZoKTtcbn1cblxuLlRyYW5zaXRpb25Jbi0tZmFkZUluIHtcbiAgb3BhY2l0eTogMTtcbiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDApO1xufVxuXG4uVHJhbnNpdGlvbkluLS1mYWRlQ2hpbGRyZW4gPiAqIHtcbiAgb3BhY2l0eTogMDtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSA0MDBtcyBjdWJpYy1iZXppZXIoMC41NSwgMC4wNTUsIDAuNjc1LCAwLjE5KTtcbiAgdHJhbnNpdGlvbi1kZWxheTogMzAwbXM7XG5cbiAgJi5UcmFuc2l0aW9uSW4tLWZhZGVJbiB7XG4gICAgb3BhY2l0eTogMTtcbiAgfVxufVxuIl19 */