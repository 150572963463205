$breakpoint-xs: 620px;
$breakpoint-md: 900px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;
$transition-slowest: 1s;

$input-bg: #303030;

$share-btn-padding-x: 8px !default;
$share-btn-padding-x-sm: 6px !default;
$share-btn-padding-x-xs: 5px !default;
$share-btn-padding-x-lg: 16px !default;
$share-btn-padding-y: $share-btn-padding-x * 2 !default;
$share-btn-border-radius: 4px !default;
$share-btn-font-size: 13px !default;
