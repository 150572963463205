.HideOffscreen__media {
  display: none !important;
}

.HideOffscreen__media--onscreen {
  display: block !important;
}

.HideOffscreen__media--offscreen {
  display: none !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvaGlkZS1vZmZzY3JlZW4iLCJzb3VyY2VzIjpbImhpZGUtb2Zmc2NyZWVuLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5IaWRlT2Zmc2NyZWVuX19tZWRpYSB7XG4gIGRpc3BsYXk6IG5vbmUgIWltcG9ydGFudDtcbn1cblxuLkhpZGVPZmZzY3JlZW5fX21lZGlhLS1vbnNjcmVlbiB7XG4gIGRpc3BsYXk6IGJsb2NrICFpbXBvcnRhbnQ7XG59XG5cbi5IaWRlT2Zmc2NyZWVuX19tZWRpYS0tb2Zmc2NyZWVuIHtcbiAgZGlzcGxheTogbm9uZSAhaW1wb3J0YW50O1xufVxuIl19 */