[data-effects] .effects-clones-wrapper,
.section-thumbnail-container .effects-clones-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}
[data-effects] .effects-clones-wrapper.is-fixed,
.section-thumbnail-container .effects-clones-wrapper.is-fixed {
  max-height: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvYmFja2dyb3VuZC1tZWRpYSIsInNvdXJjZXMiOlsiZWZmZWN0cy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0FBQUE7RUFFRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7QUFBQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiW2RhdGEtZWZmZWN0c10gLmVmZmVjdHMtY2xvbmVzLXdyYXBwZXIsXG4uc2VjdGlvbi10aHVtYm5haWwtY29udGFpbmVyIC5lZmZlY3RzLWNsb25lcy13cmFwcGVyIHtcbiAgcG9zaXRpb246IHN0aWNreTtcbiAgdG9wOiAwO1xuICBsZWZ0OiAwO1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBtYXgtaGVpZ2h0OiAxMDB2aDtcblxuICAmLmlzLWZpeGVkIHtcbiAgICBtYXgtaGVpZ2h0OiAxMDAlO1xuICB9XG59XG4iXX0= */