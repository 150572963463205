@import "../responsive/breakpoints";

.Header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;

  @media (max-width: 1100px) {
    &:has(> .Navigation__hamburger) ~ sh-reading-progress-bar {
      position: absolute;
      top: 0;
    }
  }
}

.isAnimated {
  clip-path: inset(-0.5px); // NOTE: Forcing the clip-path to clip to the next pixel up (to 'cover' antialiasing issues).
  .DeviceDetect--isIE & .Theme-Layer-TextBlock.Layer--one {
    // AI text that has depth needs a different z-index for IE
    z-index: 102;
  }
}

.Header__socialIcons a {
  font-size: 0;
  color: transparent;
}

@include media-breakpoint-up(md) {
  .Header .Navigation {
    // make sure the nav area grow, while the other don't
    // Some namespace abuse here, but this is only nav layout
    // styles relevant inside the header, so kinda makes sense?
    flex-grow: 1;
  }

  .Header__socialIcons {
    // prevent shrinking on social icons, so they stay on the same line
    flex-grow: 0;
  }
}
@include media-breakpoint-down(md) {
  // MAKE SPACE FOR HAMBURGER
  .Header__socialIcons {
    position: absolute;
    right: 60px;
    top: 0;
  }
}
