.DeviceDetect--isBot {
  $displayContainerHeight: 600px;
  @import "../responsive/orientation";
  .DisplayContainerHeight {
    height: $displayContainerHeight !important;
    overflow: hidden;
  }

  .DisplayContainerHeight--minHeight {
    min-height: $displayContainerHeight !important;
  }

  @include orientation-landscape {
    .DisplayContainerHeight--landscapeOnly {
      height: $displayContainerHeight !important;
    }
  }

  @include orientation-portrait {
    .DisplayContainerHeight--portraitOnly {
      height: $displayContainerHeight !important;
    }
  }

  .DisplayContainerHeight--height150 {
    min-height: 150vh;
  }

  .DisplayContainerHeight--heightHalf {
    height: calc($displayContainerHeight / 2) !important;
    overflow: hidden;
  }

  .DisplayContainerHeight--minHeightHalf {
    min-height: calc($displayContainerHeight / 2) !important;
  }

  .DisplayContainerHeight--heightThird {
    height: calc($displayContainerHeight / 3) !important;
    overflow: hidden;
  }

  .DisplayContainerHeight--minHeightThird {
    min-height: calc($displayContainerHeight / 3) !important;
  }
}
