[data-lazyload-item] img, [data-lazyload-item] > div {
  opacity: 0;
  transition: 1000ms ease-out opacity;
}

.Lazyload--isLoaded [data-lazyload-item] img, .Lazyload--isLoaded [data-lazyload-item] > div {
  opacity: 1;
}

.Lazyload__loading {
  transition: opacity 100ms ease-out;
  transition-delay: 1s;
  opacity: 1;
}
.Lazyload--isLoaded .Lazyload__loading {
  opacity: 0;
}

.DeviceDetect--isiOS .Lazyload__spinner {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvbGF6eWxvYWQiLCJzb3VyY2VzIjpbImxhenlsb2FkLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFFRTtFQUNBOzs7QUFLRjtFQUVFOzs7QUFJSjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOzs7QUFJSjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiW2RhdGEtbGF6eWxvYWQtaXRlbV0ge1xuICAmIGltZyxcbiAgJiA+IGRpdiB7XG4gICAgb3BhY2l0eTogMDtcbiAgICB0cmFuc2l0aW9uOiAxMDAwbXMgZWFzZS1vdXQgb3BhY2l0eTtcbiAgfVxufVxuXG4uTGF6eWxvYWQtLWlzTG9hZGVkIFtkYXRhLWxhenlsb2FkLWl0ZW1dIHtcbiAgJiBpbWcsXG4gICYgPiBkaXYge1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cbn1cblxuLkxhenlsb2FkX19sb2FkaW5nIHtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAxMDBtcyBlYXNlLW91dDtcbiAgdHJhbnNpdGlvbi1kZWxheTogMXM7XG4gIG9wYWNpdHk6IDE7XG5cbiAgLkxhenlsb2FkLS1pc0xvYWRlZCAmIHtcbiAgICBvcGFjaXR5OiAwO1xuICB9XG59XG5cbi5EZXZpY2VEZXRlY3QtLWlzaU9TIC5MYXp5bG9hZF9fc3Bpbm5lciB7XG4gIGRpc3BsYXk6IG5vbmU7XG59XG4iXX0= */