/* Rules specific to embedding */
.shorthand-embed-article .FullSize {
  height: 100%;
}

.shorthand-embed-article .DisplayContainerHeight {
  height: 100vh;
}

.shorthand-embed-article .FullSize--basic {
  height: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcHJvZHVjdGlvbi9zdG9yeS9zcmMvZW1iZWQiLCJzb3VyY2VzIjpbImVtYmVkLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7QUFDQTtFQUNFOzs7QUFFRjtFQUNFOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLyogUnVsZXMgc3BlY2lmaWMgdG8gZW1iZWRkaW5nICovXG4uc2hvcnRoYW5kLWVtYmVkLWFydGljbGUgLkZ1bGxTaXplIHtcbiAgaGVpZ2h0OiAxMDAlO1xufVxuLnNob3J0aGFuZC1lbWJlZC1hcnRpY2xlIC5EaXNwbGF5Q29udGFpbmVySGVpZ2h0IHtcbiAgaGVpZ2h0OiAxMDB2aDtcbn1cbi5zaG9ydGhhbmQtZW1iZWQtYXJ0aWNsZSAuRnVsbFNpemUtLWJhc2ljIHtcbiAgaGVpZ2h0OiAxMDAlO1xufVxuIl19 */