@import "../responsive/breakpoints";
@import "../levels/zindexes";
@import "../layout/snap";

.Navigation {
  text-align: center;

  .Project-Header &:not(.Navigation--isActive) {
    background-color: transparent !important;
  }
}

.Navigation__itemList {
  margin: 0;
  padding: 0;
  list-style: none;
}

@include media-breakpoint-up(lg) {
  // Some funky layout to center the nav for bigger viewports:
  // We cannot use Layout__snap because it applies top/left values, which
  // unfortunately get transition if the viewport is sized down, switching
  // to side navigation mode (a.k.a hamburger nav).
  .Navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .Navigation__item {
    // add some space between the nav items
    margin: 0 0.5rem;
  }
}

@include media-breakpoint-down(md) {
  .Navigation {
    z-index: map-get($levels, mobileNav);
    position: fixed;
    top: 60px;
    right: -110% !important;
    bottom: 0;
    width: 100vw;
    transition: right 0.15s ease-in-out;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
  }

  .Navigation--isActive {
    right: 0 !important;
    opacity: 1;
  }

  .Navigation__item {
    display: block;
    width: 100%;
    padding: 2vh 0;
    margin: 0;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
      text-decoration: underline;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

/**
 * Hamburger styling
 */

.Navigation__hamburger {
  z-index: map-get($levels, hamburger);

  $Hamburger-color: black;
  $hamburger-height: 32px;

  height: $hamburger-height;
  width: $hamburger-height;
  cursor: pointer;
  transform: rotate(0deg);
  margin-top: calc(-1 * ($hamburger-height / 2.2));

  @at-root button.Navigation__hamburger {
    border: none;
    background-color: transparent;
  }

  span {
    display: block;
    position: absolute;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.15s ease-in-out;
  }

  span:nth-child(1) {
    top: 5px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: floor(calc($hamburger-height / 4)) + 5;
  }

  span:nth-child(4) {
    top: floor(calc($hamburger-height / 4)) * 2 + 5;
  }

  &--initializing {
    opacity: 0;
    pointer-events: none;
  }

  &[aria-expanded="true"] {
    span:nth-child(1) {
      top: 21px;
      width: 0;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 20px;
      width: 0;
      left: 50%;
    }
  }
}
