.TransitionIn--fade {
  opacity: 0;
  transition: opacity 900ms cubic-bezier(0.86, 0.01, 1, 1);
}

.TransitionIn--up {
  transform: translateY(5vh);
}

.TransitionIn--fadeIn {
  opacity: 1;
  transform: translateY(0);
}

.TransitionIn--fadeChildren > * {
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-delay: 300ms;

  &.TransitionIn--fadeIn {
    opacity: 1;
  }
}
