@import "../responsive/breakpoints";

.Theme-RelatedStoriesSection {
  .Layer--one {
    // OVERRIDE .Layer--one Z-INDEX TO ALLOW CAPTIONS TO BE CLICKABLE
    z-index: initial;
    .Layout {
      z-index: 100;
    }
  }
}

.related-stories-list {
  .Theme-Layer-BodyText {
    padding-top: 0;
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.related-stories-list-item {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.Theme-RelatedStories--List {
  .related-story-card .related-story-text {
    width: 100%;
  }
  .related-story-image {
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(md) {
    .Theme-Layer-BodyText {
      padding: 0;
    }
  }
}

.Theme-RelatedStories--TitleSplitFirstRow,
.Theme-RelatedStories--TitleFullFirstRow,
.Theme-RelatedStories--TitleTallFirstItem,
.Theme-RelatedStories--TitleWideFirstItem,
.Theme-RelatedStories--TitleTallWideFirstItem,
.Theme-RelatedStories--Title {
  .related-story-image picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .crop-square,
  .crop-landscape,
  .crop-portrait,
  .crop-circle {
    padding: 0%;
    picture {
      overflow: visible;
      padding: 0;
    }
    img {
      overflow: visible;
      border-radius: 0;
      transform: none;
    }
  }
}

.Theme-RelatedStoriesSection {
  .Theme-RelatedStories--TitleSplitFirstRow {
    .related-stories-list ul {
      li {
        width: 33.333%;
        aspect-ratio: 16 / 9;

        &:nth-of-type(1) {
          width: 50%;
        }
        &:nth-of-type(2) {
          width: 50%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .related-stories-list ul {
        display: block;

        li,
        li:nth-of-type(1),
        li:nth-of-type(2) {
          aspect-ratio: auto;
          width: 100%;
        }
      }
    }
  }
  .Theme-RelatedStories--TitleFullFirstRow {
    .related-stories-list ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1400px;
      margin: auto;

      li {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16 / 9;

        &:nth-of-type(1) {
          grid-column-end: span 3;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .related-stories-list ul {
        display: block;

        li,
        li:nth-of-type(1) {
          aspect-ratio: auto;
        }
      }
    }
  }
  .Theme-RelatedStories--TitleTallFirstItem {
    .related-stories-list ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1400px;
      margin: auto;

      li {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16 / 9;

        &:nth-of-type(1) {
          grid-row-end: span 2;
          aspect-ratio: auto;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .related-stories-list ul {
        display: block;

        li,
        li:nth-of-type(1) {
          aspect-ratio: auto;
        }
      }
    }
  }
  .Theme-RelatedStories--TitleWideFirstItem {
    .related-stories-list ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1400px;
      margin: auto;

      li {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16 / 9;

        &:nth-of-type(1) {
          grid-column-end: span 2;
          aspect-ratio: auto;
        }
        &:nth-of-type(2) {
          aspect-ratio: auto;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .related-stories-list ul {
        display: block;

        li,
        li:nth-of-type(1) {
          aspect-ratio: auto;
        }
      }
    }
  }
  .Theme-RelatedStories--TitleTallWideFirstItem {
    .related-stories-list ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1400px;
      margin: auto;

      li {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16 / 9;

        &:nth-of-type(1) {
          grid-row-end: span 2;
          grid-column-end: span 2;
          aspect-ratio: auto;
        }
      }
    }

    @media screen and (max-width: 900px) {
      .related-stories-list ul {
        display: block;

        li,
        li:nth-of-type(1) {
          aspect-ratio: auto;
        }
      }
    }
  }
}

.related-stories-site-map {
  li {
    font-weight: bold;
  }
  ul ul {
    margin: 0;
    list-style-type: revert;
    li {
      font-weight: normal;
      font-size: 90%;
    }
  }
  ul ul ul li {
    font-style: italic;
  }
}

.related-story-card {
  display: flex;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.related-story-image .crop-undefined,
.related-story-image .crop-uncropped {
  height: 100%;
  img {
    height: 100% !important;
  }
}

.related-story-image picture {
  background: black;
}

.Theme-RelatedStories--Title a.related-story-card {
  background: none;
}
