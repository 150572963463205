@media print {
  img {
    page-break-inside: avoid !important;
    max-width: 100% !important;
  }

  .effects-clones-wrapper {
    //prevent background image scroll effects from showing up in print view
    img {
      transition: none;
      transform: none !important;
      filter: none !important;
    }

    :not(.Theme-Item-InstantImage) > img {
      opacity: 1 !important;
    }
  }

  // experimentation with orientation source

  @page {
    margin: 0.2in;
    -webkit-print-color-adjust: exact;
  }

  // .Theme-BackgroundVideo,
  .MediaSection--embed {
    display: none !important;
    page-break-after: always;
    height: 0 !important;
  }
  .InlineMedia--embed {
    display: none !important;
  }

  //Make sure custom HTML sections and collections break onto a new page
  .Theme-CollectionSectionSection,
  .Theme-CustomHtmlSection {
    min-height: 80vh !important;
  }

  //remove background for dark section

  .Lazyload__loading {
    display: none !important;
  }

  .Theme-MediaSection,
  .Theme-TwoColumnScrollmationSection {
    .FullSize {
      position: relative !important;
    }
  }

  //Transitions

  .TransitionIn--fade {
    opacity: 1 !important;
    transition: none !important;
  }

  .Theme-TitleSection {
    page-break-inside: avoid !important;
    max-height: calc(100vh - 80px) !important;
    min-height: calc(100vh - 80px) !important;
  }

  .Theme-TitleSection,
  .Theme-TextSection,
  .Theme-TwoColumnScrollmationSection,
  // .Theme-TextOverMediaSection,
  .Theme-GridSection,
  .Theme-BackgroundScrollmationSection,
  .Theme-MediaGallerySection,
  .Theme-CollectionSectionSection,
  .Theme-CustomHtmlSection,
  .Theme-MediaSection {
    page-break-after: always;
  }

  [class*="Theme-Text"] {
    will-change: none !important;
    opacity: 1 !important;
    transform: none !important;
    filter: none !important;
  }

  .Theme-HeaderContainer {
    position: relative !important;
  }

  html:not(.DeviceDetect--isIE):not(.DeviceDetect--isEdge) .isAnimated > div:not(.WebGLMissing) .AnimatedImage__fallback,
  html:not(.DeviceDetect--isIE):not(.DeviceDetect--isEdge) .isAnimated > div:not(.WebGLMissing) .AnimatedImage__fallbackOverlay {
    display: block !important;
  }

  .DisplayContainerHeight--height150,
  .DisplayContainerHeight--minHeight {
    min-height: calc(100svh - 80px) !important;
  }

  //make background image fit on the first page.
  .DisplayContainerHeight--flex .DisplayContainerHeight img {
    min-height: calc(100svh - 80px) !important;
  }

  :not(.DeviceDetect--isiOS) .FullSize--fixedChild {
    clip: none !important;
  }

  .InstantImage__img {
    filter: none !important;
  }

  .FullSize--fixedChild .FullSize__fixedChild {
    position: relative !important;
    display: block !important;
  }

  .Theme-Section {
    background-repeat: no-repeat;
    position: relative !important;
  }
  .Theme-TitleSection {
    background-repeat: no-repeat;

    //make bg media fit within title page
    .Theme-BackgroundMedia {
      height: 90% !important;
    }
  }

  .Theme-MediaSection .Theme-Layer-ResponsiveMedia-image img {
    //may need to investigate further best height for Media Section image

    max-height: 80vh;
    object-fit: contain;
    page-break-inside: avoid;
  }

  .DisplayContainerHeight--flex .Layer--one {
    padding: 20px !important;
  }

  .InlineMedia {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .Theme-Layer-BodyText p,
  .Theme-Story p {
    font-size: 14px;
  }

  .Theme-CardCanvas-ImageItem img {
    page-break-inside: avoid;
  }

  .Theme-BackgroundScrollmationSection {
    .BackgroundScrollmationColumn {
      position: relative !important;
    }
    .BackgroundScrollmationItem {
      height: 100vh !important;
      width: 100vw !important;
      position: relative !important;
      page-break-inside: avoid;
    }

    .Layer--one {
      position: absolute !important;
      width: 100% !important;
    }

    .MediaRenderer__fixedCaption--div {
      display: none !important;
    }

    .BackgroundScrollmationItem {
      max-width: 100% !important;
      .MediaRenderer__fixedCaption {
        position: absolute !important;
      }
    }

    .Theme-Layer-BodyText {
      margin-top: 100px !important;
    }
  }

  .Theme-BackgroundScrollmationSection.Theme-BodyTextColumn-Center .Theme-OverlayedCaption {
    left: auto !important;
    max-width: 25% !important;
    right: 0 !important;
  }

  //Inline Elements

  .InlineElement--Table {
    page-break-inside: avoid;
  }

  .PositionSticky {
    position: relative !important;
    top: 0;
    position: relative !important;
  }

  //Scrollmation

  .Theme-BodyTextColumn-Left {
    .CardCanvas__textColumn {
      margin: 0 !important;
    }
  }

  .Theme-Layer-CardCanvas[data-card-canvas] {
    margin-top: 0 !important;
  }

  .CardCanvasItem {
    padding-right: 20% !important;
    padding-left: 20% !important;
    margin-left: -20% !important;
  }

  .Theme-TwoColumnScrollmationSection {
    min-height: 100% !important;
    position: relative !important;

    .Layer--one {
      max-width: 95vw !important;
    }

    .Layout--equalHeights {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .Layout {
      width: 100% !important;
    }

    .Theme-Layer-CardCanvas {
      height: auto !important;
      padding: none !important;
    }

    .PositionSticky {
      position: relative !important;
      top: 0;
      position: relative !important;
    }

    .Theme-Layer-CardCanvas {
      min-height: 100vh !important;
      width: 100%;
      img {
        page-break-inside: avoid;
      }
    }

    .CardCanvasItem {
      width: 100% !important;
      .crop-uncropped {
        img {
          object-fit: contain !important;
        }
      }

      //This adjusts the media column and cropped/uncropped media

      .CardCanvas--tworowContainer .CardCanvas {
        box-shadow: none !important;
      }
    }
  }

  //Media-gallery
  .MediaGallery {
    .MediaGallery__cell {
      max-height: 50vh !important;
    }

    video {
      display: none !important;
    }
    img {
      visibility: visible !important;
    }
  }

  .hide-caption {
    visibility: visible !important;
  }

  // .carousel-captions {

  .Theme-Section-Dark .Theme-Caption p,
  .Theme-Section-Dark .Theme-Layer-BodyText-Blockquote,
  .Theme-Section-Dark .Theme-Layer-BodyText-QuoteCite {
    color: #333 !important;
  }
  // }
  .MediaGallery__cell .Caption--hasOverlay {
    bottom: 0 !important;
    opacity: 1 !important;
  }

  //Carousel

  .carousel-inner {
    display: flex !important;
    flex-wrap: wrap !important;
    transition: none !important;
    transform: none !important;
  }

  .carousel-cell-item,
  .carousel-cell-print {
    flex: 1 0 30% !important;
    max-width: 30vw !important;
    margin: 5px; /* Optional: add some space between the images */
    opacity: 1 !important;

    .FullSize {
      position: relative !important;
    }
  }

  .MediaGallery_carousel {
    .print-media-carousel-cell .Theme-BackgroundMedia {
      height: 30vh;
    }
  }

  .MediaGallery_carousel .non-active-fade-out {
    opacity: 1 !important;
    animation: none !important;
  }

  .MediaGallery_carousel {
    overflow: visible !important;
    display: block !important;
  }

  .carousel-inner img {
    object-fit: cover !important;
  }

  .carousel-inner-Medium,
  .carousel-inner-Small,
  .carousel-inner-Large {
    height: auto !important;
  }

  .carousel-button {
    display: none !important;
  }

  .fade-at-end {
    mask-image: none !important;
  }

  .Theme-Section-Dark.Theme-BackgroundScrollmationSection .Theme-Overlay {
    background: #000 !important;
    box-shadow: none !important;
    width: 100% !important;
  }

  // Grid
  .GridSection__row {
    flex-direction: row !important;
    align-items: initial !important;
  }

  .GridItem {
    padding: 5px !important;
  }

  //Text Over Media section

  .Theme-TextOverMediaSection {
    .AnimatedImage__background,
    sh-model,
    canvas,
    object {
      display: none !important;
      height: 0 !important;
      z-index: 0 !important;
    }

    .SplitLayout .FullSize--fullHeight {
      min-height: 90vh !important;
    }

    .Theme-ItemPicture {
      img {
        width: 100% !important;
        display: block !important;
      }
    }

    //make background

    .Theme-BackgroundVideo {
      video {
        display: none !important;
        visibility: hidden !important;
      }

      .BackgroundVideo__fallback {
        img {
          visibility: visible !important;
        }
      }
      .Theme-Item-InstantImage {
        display: none !important;
      }
    }
  }

  //reveal

  .Theme-RevealSection {
    position: relative !important;
    height: 100% !important;
    min-height: 100vh !important;

    .Lazyload__loading {
      display: none !important;
    }

    video {
      display: none !important;
      visibility: hidden !important;
    }
    .BackgroundVideo__fallback {
      img {
        visibility: visible !important;
      }
    }
    .Theme-Item-InstantImage {
      display: none !important;
    }

    .FullSize .Lazyload__loading {
      display: none !important;
      height: 0 !important;
    }

    .FullSize--fullWidth {
      opacity: 1 !important;
      display: block !important;
      position: relative !important;
      clip-path: none !important;
      height: 90vh !important;
      page-break-after: always !important;
      .FullSize {
        position: relative !important;
      }
    }

    .InlineMedia {
      .FullSize {
        position: relative !important;
      }
    }

    .MediaRenderer__textBlocks {
      position: absolute !important;
      width: 100% !important;
      top: 0 !important;
      margin-top: 0 !important;

      .Theme-Layer-BodyText {
        opacity: 1 !important ;
      }
    }

    .MediaRenderer__textBlocksInner > div {
      padding-top: 10% !important;
      padding-bottom: 0 !important;
    }

    //caption

    .MediaRenderer__fixedCaption {
      position: relative !important;
    }
    .MediaRenderer__fixedCaption.MediaRenderer__captionDisplay {
      transition: none !important;
    }

    .MediaRenderer__textBlocksInner_slowest,
    .MediaRenderer__textBlocksInner_fastest {
      min-height: 105vh !important;

      &:first-child {
        min-height: 45vh !important;
      }

      // IE11 and Edge don't like negative top margins so we reset it just for them
      .DeviceDetect--isIE &:first-child,
      .DeviceDetect--isEdge &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        min-height: 160vh !important;
      }

      /// Actual text content:

      &:first-child > div {
        padding-top: 80vh !important;
      }

      & > div {
        min-height: 100vh !important;
        background: transparent !important;

        // Make sure the text is under the caption
        z-index: 0 !important;

        // Prop up each Slate section so that there is only ever one on
        // screen at once (should add up to around 200vh)
        padding-top: 10% !important;
        padding-bottom: 0 !important;
      }
    }
  }

  .PreviewFooter {
    display: none !important;
    height: 0 !important;
  }
}

@media print and (orientation: portrait) {
  /* Styles for print in portrait mode */

  .Theme-TwoColumnScrollmationSection {
    .Theme-Layer-BodyText {
      padding-top: 30px !important;
      max-width: 80% !important;
    }

    .Layout__col-sm-10 {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }

    object {
      position: relative !important;
    }

    .CardCanvas--tworowContainer .CardCanvas__textColumn {
      padding-top: 0 !important;
    }

    .Display--block.FullSize--basic.CardCanvasItem__image {
      page-orientation: portrait;
      display: block;
      position: relative !important;
    }

    .Layout__row {
      flex-wrap: nowrap !important;
    }

    .CardCanvas--tworowContainer .CardCanvas {
      box-shadow: none !important;
      -webkit-backdrop-filter: none !important;
      backdrop-filter: none !important;
    }
  }
}
