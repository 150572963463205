@import '../core/bootstrap-variables';

/** BlockPositiong a.k.a snap */

$gutter-size: calc($grid-gutter-width / 2);


@mixin snap-x($x) {
  position: absolute;

  @if $x == left {
    left: 0;
    text-align: left;
  }

  @if $x == center {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  @if $x == right {
    right: 0;
    text-align: right;
  }
}

@mixin snap-y($y) {
  position: absolute;

  @if $y == top {
    top: 0;
  }

  @if $y == center {
    top: 50%;
    transform: translateY(-50%);
  }

  @if $y == bottom {
    bottom: 0;
  }
}

@mixin snap($x, $y) {
  @include snap-x($x);
  @include snap-y($y);

  @if $x == center and $y == center {
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
