@use "@shorthand/design-system/colours" as colours;
@use "@shorthand/design-system/fonts" as fonts;
@import "./_footer-variables.scss";

.PromoFooter {
  background-color: white;
  border-top: 1px solid rgba(black, 0.1);
  color: colours.$index-1;
  text-align: center;
  font-family: fonts.$font-family-primary;
  overflow: hidden;

  @media (min-width: $breakpoint-xxl) {
    display: flex;
    padding: 0 25px;
    align-items: center;

    .Layout {
      flex: 1;
      max-width: none;
    }
  }

  .PromoFooter--inner {
    display: block;
    z-index: 1;
    padding: 23px 25px 25px;
    margin: 0 auto;
    font-size: 16px;
    max-width: 90%;
    min-width: 300px;
    color: inherit;
    text-decoration: none;
    transition: opacity 0.15s ease;

    @media (min-width: $breakpoint-xs) {
      font-size: 17px;
    }

    @media (min-width: $breakpoint-md) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint-xl) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      padding-bottom: 35px;
      max-width: 100%;
    }

    @media (min-width: $breakpoint-xxl) {
      justify-content: flex-start;
    }

    &:hover,
    &:focus {
      opacity: 0.9;
    }

    .PromoFooter--logoContainer {
      display: block;
      width: 35px;
      height: 35px;
      font-size: 0;
      margin: 0 auto 5px;

      @media (min-width: $breakpoint-xl) {
        margin: 0 10px 0 0;
      }

      .PromoFooter--logo {
        width: 100%;
        height: auto;
      }
    }

    .PromoFooter--title {
      font-family: inherit;
      font-size: 1.5em;
      line-height: 1.15;
      margin: 0 auto;
      font-weight: normal;

      @media (min-width: $breakpoint-xl) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.3em;
        width: auto;
        margin: 0 5px 0 0;
      }

      .show-large {
        display: none;

        @media (min-width: $breakpoint-xl) {
          display: initial;
        }
      }
    }

    .PromoFooter--text {
      opacity: 0.7;
      font-size: 1.3em;
      line-height: 1.15;
      font-family: inherit;
      margin: 0.25em auto 0.35em;

      @media (min-width: $breakpoint-xl) {
        font-size: 1.3em;
        text-align: left;
        width: auto;
        margin: 0 0 0 5px;
      }
    }
  }
}

.PromoFooterTopLink {
  z-index: 1;
  display: inline-block;
  font-size: 11px;
  font-family: fonts.$font-family-primary;
  padding: 12px 25px 15px;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.15s ease;
  width: 100%;
  border-top: 1px solid rgba(black, 0.05);

  &:before {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: "";
    display: block;
    height: 16px;
    left: 50%;
    position: relative;
    top: 8px;
    margin-bottom: 4px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 16px;
    margin-left: -8px;
    transition: top 0.15s ease;
  }

  &:hover,
  &:focus {
    opacity: 0.8;

    &:before {
      top: 5px;
    }
  }

  @media (min-width: $breakpoint-xxl) {
    width: 100px;
    align-items: center;
    border: none;
    border-left: 1px solid rgba(black, 0.05);
    padding: 0;
  }
}
