.ObjectFit--cover {
  object-fit: cover;
}

.ObjectFit--contain {
  object-fit: contain;
  // TODO: Force top position for 2col thumbs in editor
  // We should find a better solution for that in the future
  object-position: top;
}

.__FauxObjectFit__ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.ObjectFit--hasFauxImg {
  visibility: hidden;
}
