$levels: (
  thePits: -30,
  underground: -20,
  basement: -10,
  groundZero: 0,
  one: 15,
  two: 25,
  three: 35,
  four: 45,
  five: 55,
  six: 65,
  seven: 75,
  eight: 85,
  nine: 95,
  ten: 105,
  god: 1000,

  /**
   * Component specific levels
   */
  header: 200,
  hamburger: 220,
  mobileNav: 210,
);
